import React, { useContext } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import LazyBlur from "~/components/LazyBlur";
import AnimateMount from "~/components/AnimateMount";

const NewsGrid = ({
  items,
  icon = null,
  showUrl = false,
  minHeight = "auto",
  limit = undefined,
}) => {
  const { hyphenParse } = useContext(DataContext);
  const hasImage = (itemToCheck) => {
    let bilder = itemToCheck.bilder;
    if (bilder && Array.isArray(bilder) && bilder.length > 0) {
      if (bilder[0]?.umbracoFile) {
        return true;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      {items && items.length > 0 && (
        <div className="nth-grid row">
          {items.map(
            (item, index) =>
              item.url &&
              (limit ? index < limit : true) && (
                <InternalOrExternalLink
                  to={item.url}
                  style={{
                    minHeight:
                      typeof (item.intro || item.bodyText) === "string" &&
                        minHeight
                        ? minHeight
                        : "",
                  }}
                  className={`nth-grid__item ${hasImage(item) ? "" : "nth-grid__item--no-bg"
                    }`}
                >
                  <div
                    className="nth-grid__item__bg-wrapper"
                  // onMouseEnter={(e) =>
                  //   (e.target.style.clipPath = `polygon(50% 0%, 100% 10%, ${e.clientX}% 100%, 0% 100%, 50% 50%, 0% 0%)`)
                  // }
                  // onMouseLeave={(e) =>
                  //   (e.target.style.clipPath =
                  //     "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%)")
                  // }
                  >
                    {hasImage(item) ? (
                      <LazyBlur
                        placeholder={`${item.bilder[0]?.umbracoFile || item.seoImage?.url
                          }?width=50&height=50&mode=crop&quality=20`}
                        src={`${item.bilder[0]?.umbracoFile || item.seoImage?.url
                          }?width=600&height=600&mode=crop&quality=70`}
                        className="nth-grid__item__bg"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={`nth-grid__item__overlay`}></div>
                  {/* <img loading="lazy" src={item.bilder[0]?.url} /> */}
                  {/* {JSON.stringify(item.bilder[0])} */}
                  <div className={`nth-grid__item__content`}>
                    {typeof (item.intro || item.bodyText) === "string" && (
                      <div className="nth-grid__item__content__text mb-4">
                        {hyphenParse(item.intro || item.bodyText)}
                      </div>
                    )}
                    <div className={`nth-grid__item__content__body`}>
                      {typeof item.title === "string" && (
                        <div className="nth-grid__item__content__body__title">
                          {hyphenParse(item.title)}
                        </div>
                      )}
                      {typeof item.url === "string" && (
                        <AnimateMount trigger={showUrl}>
                          <p className="small nth-grid__item__content__body__url">
                            {hyphenParse(item.url)}
                          </p>
                        </AnimateMount>
                      )}
                      {icon ? (
                        <div className="nth-grid__item__content__body__arrow-right">
                          {icon}
                        </div>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="33.763"
                          height="59.201"
                          viewBox="0 0 33.763 59.201"
                          className={`nth-grid__item__textwrapper__arrow-right`}
                        >
                          <path
                            id="Path_358"
                            data-name="Path 358"
                            d="M5217.978,1504.771l26.521,25.359-26.521,25.358"
                            transform="translate(-5213.736 -1500.529)"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                </InternalOrExternalLink>
              )
          )}
        </div>
      )}
    </>
  );
};

export default NewsGrid;
