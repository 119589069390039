import React from "react";

// Internal components
import NewsGrid from "~/components/NewsGrid";
import NthGrid from "~/components/NthGrid";

const FeaturedGrid = ({ items, limit, minHeight, variant }) => {
  if (variant.toLowerCase() === "annehver bilde og tekst") {
    return (
      <NthGrid
        items={items}
        limit={limit === "0" ? undefined : limit}
        minHeight={minHeight}
      />
    );
  } else {
    return (
      <NewsGrid
        items={items}
        limit={limit === "0" ? undefined : limit}
      // minHeight={minHeight}
      />
    );
  }
};

export default FeaturedGrid;
