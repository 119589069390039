import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// External components
import SVG from "react-inlinesvg";
import Toggle from "react-toggle";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ImageFadeIn from "react-image-fade-in";
import slugify from "slugify";

// Internal components
import AnimateMount from "~/components/AnimateMount";
import UmbracoFileGallery from "~/components/UmbracoFileGallery";
import { BsArrowUpShort } from "react-icons/bs";

const BuildingPicker = ({ allBuildings, page }) => {
  const [activeBuilding, setActiveBuilding] = useState(null);
  const [scrollToAnchor, setScrollToAnchor] = useState(true);
  const [showReset, setShowReset] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [disablePanAndZoom, setDisablePanAndZoom] = useState(true);

  const location = useLocation();
  useEffect(() => {
    let buildingHash = location?.hash;
    if (buildingHash) {
      let active = allBuildings?.items[0].children?.filter(
        (b) =>
          b.title.toLowerCase() ===
          buildingHash?.replace(/#/g, "").toLowerCase()
      );
      if (active) {
        setActiveBuilding(active[0]);
      }
    }
  }, [location, allBuildings]);

  const [images, setImages] = useState(null);
  useEffect(() => {
    if (activeBuilding?.images) {
      let allImages = [];
      activeBuilding?.images?.forEach((folderOrImage) => {
        if (folderOrImage?.children?.length > 0) {
          folderOrImage.children?.forEach((child) => {
            allImages.push(child);
          });
        } else {
          allImages.push(folderOrImage);
        }
      });
      setImages(allImages);
    } else {
      setImages(null);
    }
  }, [activeBuilding]);

  return (
    <section>
      {allBuildings.items?.map((buildings) => (
        <>
          <div id="buildings-picker" className="building-picker__anchor" />
          <div className="container-xxl px-3 px-md-0">
            {buildings?.title !== page?.title && (
              <div className="d-flex align-items-center flex-wrap justify-content-between mb-3 w-100">
                <h2>{buildings.title}</h2>
              </div>
            )}
            <div className="buildings-picker-wrapper mb-5">
              <TransformWrapper
                wheel={{ disabled: disablePanAndZoom }}
                panning={{ disabled: disablePanAndZoom }}
                pinch={{ disabled: disablePanAndZoom }}
                doubleClick={{ disabled: disablePanAndZoom }}
              >
                {({
                  zoomIn,
                  zoomOut,
                  resetTransform,
                  zoomToElement,
                  setTransform,
                  centerView,
                  ...rest
                }) => (
                  <React.Fragment>
                    <div className="building-picker-buttons">
                      <div className="building-picker-buttons__zoom">
                        <button
                          onClick={() => {
                            zoomOut();
                            if (zoom > 1) {
                              setZoom(zoom - 1);
                              if (zoom === 2) {
                                setDisablePanAndZoom(true);
                              }
                            }
                          }}
                          className="btn btn-light rounded"
                        >
                          -
                        </button>
                        <button
                          onClick={() => {
                            zoomIn();
                            setShowReset(true);
                            setZoom(zoom + 1);
                            setDisablePanAndZoom(false);
                          }}
                          className="btn btn-light rounded"
                        >
                          +
                        </button>
                      </div>{" "}
                      <div>
                        {showReset && zoom !== 1 && (
                          <button
                            onClick={() => {
                              resetTransform();
                              setZoom(1);
                              setShowReset(false);
                              setDisablePanAndZoom(true);
                            }}
                            className="btn btn-light rounded"
                          >
                            Reset
                          </button>
                        )}
                      </div>
                    </div>
                    <TransformComponent>
                      <div
                        className={`building-picker rounded overflow-hidden ${!disablePanAndZoom ? "building-picker--panning" : ""
                          }`}
                      >
                        {/* <pre style={{ whiteSpace: "pre" }}>
                        {JSON.stringify(buildings?.children, null, 1)}
                      </pre> */}
                        {buildings.children?.map((building) => (
                          <>
                            <SVG
                              src={building.mask?.umbracoFile}
                              alt="all-buildings"
                              className={`building-picker__masks ${activeBuilding === building
                                ? "building-picker__masks--active"
                                : ""
                                }`}
                              onClick={(e) => {
                                setActiveBuilding(building);
                                if (scrollToAnchor) {
                                  setTimeout(() => {
                                    document.querySelector(`#${building.slug}`).scrollIntoView();
                                  }, document.querySelector(`#${building.slug}`));
                                  window.location.href = `${window.location.pathname}#${building.slug}`;
                                }
                                // setActiveBuilding(null);
                                // setTimeout(() => {
                                //   setActiveBuilding(building);
                                //   setTimeout(() => {
                                //     window.location.href = `${window.location.pathname}#${slugify(building.title)}`;
                                //   }, document.getElementById(building?.title.toLowerCase()));
                                // }, 100);
                              }}
                            ></SVG>
                            {/* <pre style={{ whiteSpace: "pre" }}>
                            {JSON.stringify(building, null, 1)}
                          </pre> */}
                          </>
                        ))}

                        <ImageFadeIn
                          src={
                            buildings.totalImage?.umbracoFile +
                            "?format=jpeg&width=2000"
                          }
                          alt="all-buildings"
                          className="building-picker__image"
                        />
                      </div>
                    </TransformComponent>
                    <label
                      htmlFor="panorering"
                      style={{ userSelect: "none" }}
                      className={`badge hover ${!disablePanAndZoom
                        ? "bg-primary-light text-primary"
                        : "bg-light text-dark"
                        } d-flex align-items-center justify-content-between gap-2 building-picker-pan-toggle`}
                    >
                      <span> pan &amp; zoom</span>
                      <Toggle
                        id="panorering"
                        className="ml-2"
                        checked={!disablePanAndZoom}
                        onChange={() => {
                          setDisablePanAndZoom(!disablePanAndZoom);
                          if (!disablePanAndZoom) {
                            resetTransform();
                            setShowReset(false);
                          }
                        }}
                      />
                    </label>
                    <label
                      style={{ userSelect: "none" }}
                      htmlFor="automatisk-scrolling"
                      className={`badge hover ${scrollToAnchor
                        ? "bg-primary-light text-primary"
                        : "bg-light text-dark"
                        } d-flex align-items-center justify-content-between gap-2 ms-auto building-picker-scroll-toggle`}
                    >
                      <span>Autoscroll til innhold</span>
                      <Toggle
                        id="automatisk-scrolling"
                        className="ml-2"
                        defaultChecked={scrollToAnchor}
                        onChange={() => setScrollToAnchor(!scrollToAnchor)}
                      />
                    </label>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
          </div>
          {activeBuilding && <ImageFadeIn
            key={activeBuilding?.closeup?.url}
            id={slugify(activeBuilding?.slug)}
            src={activeBuilding?.closeup?.url}
            width="500px"
            className="p-4"
            alt={`Nærbilde av ${activeBuilding?.slug}`}
          />}
          <AnimateMount
            key={activeBuilding?.id}
            trigger={activeBuilding}
            animationKey={activeBuilding?.id}
          >
            {activeBuilding && <>
              <>
                <div className="ui-shadow">
                  <div className="container-xxl p-md-3 mb-5">
                    <h3 className="h2">{activeBuilding.title}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: activeBuilding?.description,
                      }}
                    />
                  </div>
                  {images?.length > 0 && (
                    <UmbracoFileGallery items={images} />
                  )}
                </div>
                <div className="w-100 d-flex justify-content-center px-3 px-md-0">
                  <div
                    className="btn btn-primary mt-3"
                    onClick={() => {
                      document
                        .querySelector("#buildings-picker")
                        .scrollIntoView({
                          // behavior: "smooth",
                          // block: "start",
                          // inline: "start",
                        });
                    }}
                  >
                    Velg et annet bygg{" "}
                    <BsArrowUpShort size="1.5rem" className="ms-3" />
                  </div>
                </div>
              </>
            </>}
          </AnimateMount>
        </>
      ))}
    </section>
  );
};

export default BuildingPicker;
