import React, { useContext } from "react";
import { Link } from "react-router-dom";

// External components
import SVG from "react-inlinesvg";

// Contexts
import { DataContext } from "~/contexts/Data";

const Logo = ({ style, className, footer = false, onClick }) => {
  const { umbData } = useContext(DataContext);

  if (footer && umbData.footerLogo?.url) {
    return (
      <Link to="/" className={className} onClick={onClick}>
        <span className={`logo `}>
          <SVG src={umbData.footerLogo?.url} style={{ style }} />
        </span>
      </Link>
    );
  } else if (umbData.headerLogo?.url) {
    return (
      <Link to="/" className={className} onClick={onClick}>
        <span className={`logo `}>
          <SVG src={umbData.headerLogo?.url} style={{ style }} />
        </span>
      </Link>
    );
  } else return null;
};

export default Logo;
