/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import Header from "~/components/Header";
import PageWrapper from "~/components/PageWrapper";
import NewsGrid from "~/components/NewsGrid";
import Section from "~/components/Section";

const SubPage = ({ page, subPage, tabTitle }) => {
  const { umbData, hyphenParse, setHeaderBgImg, setCurrentRoute } = useContext(DataContext);
  useEffect(() => {
    setCurrentRoute(page);
  }, [page]);

  useEffect(() => {
    if (
      subPage.headerImg?.umbracoFile ||
      page.headerImg?.umbracoFile ||
      umbData.bgImg?.umbracoFile
    ) {
      setHeaderBgImg(
        subPage.headerImg?.umbracoFile ||
        page.headerImg?.umbracoFile ||
        umbData.bgImg?.umbracoFile
      );
    } else {
      setHeaderBgImg(umbData.bgImg?.umbracoFile);
    }
  }, [page]);
  return (
    <PageWrapper
      page={subPage}
      devData={subPage}
      title={tabTitle}
      seoTitle={subPage.seoTitle || page.title + " - " + subPage.title}
      seoDescription={subPage.seoDescription}
      seoImage={subPage.seoImage?.url}
      bilder={subPage.bilder}
      customHeader={
        <>
          <Header
            page={page}
            subPage={subPage}
            // horizontalMenu={true}
            introText={subPage.intro}
            introTitle={
              <>{(subPage.heading || subPage.title) && <h2>{hyphenParse(subPage.heading || subPage.title)}</h2>}</>
            }
            title={page.title}
            crumbs={[
              { label: page.title, url: page.url },
              { label: subPage.title, url: subPage.url },
            ]}
            bgImg={
              subPage.headerImg?.umbracoFile ||
              page?.bgImg?.umbracoFile ||
              umbData.bgImg?.umbracoFile ||
              ""
            }
          />
        </>
      }
    >
      {subPage &&
        subPage.children?.length > 0 &&
        subPage.children[0]?.type !== "ansatt" &&
        subPage.children[0]?.type !== "nyhet" && (
          <Section
            title="subSubPages"
            className="container-xxl px-0 px-sm-5 px-xxl-0"
          >
            <NewsGrid
              items={subPage.children}
              limit={subPage.children.length}
              hideText
            // minHeight={minHeight}
            />
          </Section>
        )}
      {/* {Array.isArray(page.components) &&
                    page.components.length > 0 &&
                    page.components.map((c) => <Component c={c} />)} */}
    </PageWrapper>
  );
};

export default SubPage;
