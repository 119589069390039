import React, { useContext } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

const Quote = ({ text, author, url, style, className }) => {
  const { hyphenParse } = useContext(DataContext);
  return (
    <q style={style} className={`quote ${className} mt-5`}>
      <div className="quote__text">{hyphenParse(text)}</div>
      <q cite={url} className="quote__author">
        {hyphenParse(author)}
      </q>
    </q>
  );
};

export default Quote;
