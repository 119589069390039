import React, { useContext } from "react";

// Internal components
import LazyBlur from "~/components/LazyBlur";

// External components
import { BsFillPhoneVibrateFill, BsFillEnvelopeFill } from "react-icons/bs";

// Contexts
import { DataContext } from "~/contexts/Data";

const HelpSection = ({ faq = false, className }) => {
  const { umbData, hyphenParse } = useContext(DataContext);
  return (
    <div
      className={`help-section ${!faq ? "mt-5" : "help-section--faq"
        } ${className}`}
    >
      <div>
        {umbData.helpImg?.umbracoFile && !faq && (
          <LazyBlur
            className="help-section__img"
            src={umbData.helpImg?.umbracoFile + "?width=1200&quality=70"}
            placeholder={umbData.helpImg?.umbracoFile + "?width=200&quality=20"}
          />
        )}
        {faq && (
          <p style={{ maxWidth: "50ch" }}>
            {hyphenParse(
              "Under vil du finne en rekke ofte stilte spørsmål. Vi har gjort vårt beste for å prøve å identifisere og svare på de mest vanlige spørsmålene."
            )}
          </p>
        )}
      </div>
      <div className="help-section__content">
        <hr className="help-section__content__separator" />
        {umbData.helpText && (
          <div className="help-section__content__text">
            {hyphenParse(umbData.helpText)}
          </div>
        )}
        {umbData.helpPhone && (
          <div className="help-section__content__phone mb-2">
            <BsFillPhoneVibrateFill size="1.5rem" className="me-3" />
            {umbData.helpPhone}
          </div>
        )}
        {umbData.helpEmail && (
          <div className="help-section__content__email">
            <BsFillEnvelopeFill size="1.5rem" className="me-3" />
            {umbData.helpEmail}
          </div>
        )}
      </div>
    </div>
  );
};

export default HelpSection;
