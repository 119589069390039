import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

// External components
import KeyboardEventHandler from "react-keyboard-event-handler";
import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import Banner from "~/components/Banner";
import DevMode from "~/components/DevMode";
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import ScrollTop from "~/components/ScrollTop";
import Shrug from "~/components/Shrug";
import AllRoutes from "~/components/Routes";
import Spinner from "~/components/Spinner";

const App = () => {
  const { umbData, currentRoute } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!umbData) {
      setTimeout(() => {
        setLoading(false);
      }, 12000);
    } else {
      setLoading(false);
    }
  }, [umbData]);
  useEffect(() => {
    console.log(
      '%cPress: "alt" + "shift" + "r" to reset cookie banner',
      "color:green;font-weight:bold;border:1px solid black;padding: 1rem;"
    );
    console.log(
      '%cPress: "ctrl" + "shift" + "d" to stoggle devmode',
      "color:blue;font-weight:bold;border:1px solid black;padding: 1rem;"
    );
  }, []);
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ visibility: "hidden", pointerEvents: "none", display: "none" }}>
        <defs>
          <filter id="f1" x="-20%" y="-20%" width="160%" height="160%">
            <feGaussianBlur id="b1" result="blurOut" in="SourceGraphic" stdDeviation="1"></feGaussianBlur>
            <feComposite
              id="c1"
              operator="arithmetic"
              k1={getComputedStyle(document.documentElement).getPropertyValue('--sharpen-k1')}
              k2={getComputedStyle(document.documentElement).getPropertyValue('--sharpen-k2')}
              k3={getComputedStyle(document.documentElement).getPropertyValue('--sharpen-k3')}
              k4={getComputedStyle(document.documentElement).getPropertyValue('--sharpen-k4')}
              in="SourceGraphic" in2="blurOut"></feComposite>
          </filter>
        </defs>
      </svg>
      <Banner />
      <DevMode />
      <KeyboardEventHandler
        handleKeys={["alt+shift+r"]}
        onKeyEvent={(key, e) => {
          resetCookieConsentValue("drottningborgAcceptCookies");
          window.location.reload();
        }}
      />
      <KeyboardEventHandler
        handleKeys={["alt+shift+g"]}
        onKeyEvent={(key, e) => {
          window.open(
            `https://github.com/aksellsor/07_react_drottningborg-vgs/actions`,
            "_blank"
          );
        }}
      />
      {(currentRoute) && (
        <KeyboardEventHandler
          handleKeys={["alt+shift+u"]}
          onKeyEvent={() => {
            if (window.location.hostname === "localhost") {
              window
                .open(
                  `${currentRoute
                    ? `https://drottningborg.vgs.no/umbraco#/content/content/edit/${currentRoute?.id
                    }`
                    : "https://drottningborg.vgs.no/umbraco"
                  }`,
                  "_blank"
                )
                .focus();
            } else {
              window
                .open(
                  `${currentRoute
                    ? `/umbraco#/content/content/edit/${currentRoute?.id
                    }`
                    : "/umbraco"
                  }`,
                  "_blank"
                )
                .focus();
            }
          }
          }
        />
      )}
      {/* <KeyboardEventHandler
        handleKeys={["alt+shift+u"]}
        onKeyEvent={(key, e) => {
          window.open(
            `https://drottningborg.vgs.no/umbraco`,
            "_blank"
          );
        }}
      /> */}
      {umbData && (
        <CookieConsent
          location="bottom"
          buttonText="Jeg forstår"
          cookieName="drottningborgAcceptCookies"
          style={{
            background: "#2B373B",
            width: "max-content",
            display: "flex",
            bottom: "0 env()",
            justifyContent: "center",
            flexWrap: "wrap",
            maxWidth: "100vw",
            alignItems: "center",
            padding: "0.5rem",
            zIndex: 1000,
          }}
          disableButtonStyles
          buttonClasses="btn btn-primary rounded-pill px-4 mb-4 mb-sm-2"
        >
          <p className="mb-0" style={{ maxWidth: "40ch" }}>
            Denne nettsiden bruker informasjonskapsler{" "}
            <span style={{ opacity: 0.5 }}>(cookies)</span> for å bedre
            brukeropplevelsen.{" "}
            {umbData.cookiesPage?.url && (
              <InternalOrExternalLink
                to={umbData.cookiesPage?.url}
                style={{
                  color: "#2abbe8",
                  textDecoration: "underline",
                }}
                className="d-inline-block d-sm-inline"
              >
                Les mer
              </InternalOrExternalLink>
            )}
          </p>
          {/* <span style={{ fontSize: "10px" }}>
                          This bit of text is smaller :O
                      </span> */}
        </CookieConsent>
      )}
      {umbData ? (
        <AllRoutes />
      ) : (
        <Routes>
          <Route path="*" element={
            <div
              className="d-flex align-items-center text-center justify-content-center flex-column"
              style={{ minHeight: "100vh" }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <Shrug />
                  <h1>Oops!</h1>
                  <p>
                    <strong>Her tar det visst litt lang tid.</strong> <br />
                    Kanskje best å prøve igjen senere? <br />
                    Sikkert bare noe smårusk i maskineriet
                  </p>
                  <hr style={{ borderColor: "grey" }} />
                  <p>
                    Du kan alltids kontakte oss på: <br />
                    post@drottningborg.vgs.no
                  </p>
                </>
              )}
            </div>}>
          </Route>
        </Routes>
      )}
      <ScrollTop />
    </>
  );
};

export default App;
