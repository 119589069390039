import React from "react";

// Internal components
import Section from "~/components/Section";

// External components
import { SRLWrapper } from "simple-react-lightbox";
// import UmbracoFileGallery from "~/components/UmbracoFileGallery";

const Bilder = ({ images }) => {
  // let [skipTwo, setSkipTwo] = useState(images);
  // useEffect(() => {
  //   if (images) {
  //     let arr = [];
  //     images.forEach((img, index) => (index > 1 ? arr.push(img) : null));
  //     setSkipTwo(arr);
  //   }
  // }, [images]);
  return (
    <>
      {Array.isArray(images) && images.length > 0 && (
        <Section
          title="bilder"
          devData={images}
          className="col-xl-5 ms-auto p-0 "
        >
          <SRLWrapper>
            <div className="d-flex flex-column">
              {images.map((bilde, index) => (
                // (index === 0 || index === 1) &&
                <img
                  loading="lazy"
                  src={
                    bilde.umbracoFile +
                    "?width=1200&height=500&mode=max&upscale=false"
                  }
                  className="mb-4 rounded"
                  alt={bilde.title}
                  // style={{
                  //   width: "100%",
                  //   maxHeight: 500,
                  //   objectFit: "cover",
                  // }}
                  style={{
                    width: "min-content",
                    maxWidth: "100%",
                  }}
                />
              ))}
            </div>
          </SRLWrapper>
        </Section>
      )}
      {/* {Array.isArray(images) && images.length > 1 && (
        <Section title="bilder" devData={images} className="col-12 p-0">
          <UmbracoFileGallery items={skipTwo} />
        </Section>
      )} */}
    </>
  );
};

export default Bilder;
