/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from "react";

// Internal components
import Quote from "~/components/Quote";
import Nav from "~/components/Nav";
import Breadcrumbs from "~/components/Breadcrumbs";
// import AnchorLinks from "~/components/AnchorLinks";

// Contexts
import { DataContext } from "~/contexts/Data";

// External components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, {
  Pagination,
  Autoplay,
  Keyboard,
  EffectFade,
} from "swiper/core";
SwiperCore.use([Pagination, Autoplay, Keyboard, EffectFade]);

const Header = ({
  quote,
  title,
  bgImg,
  introText,
  page,
  crumbs,
  introTitle,
  horizontalMenu,
  className,
  showLinks = true,
  subPage,
}) => {
  const { hyphenParse, setHeaderHeight, screenWidth, umbData } =
    useContext(DataContext);
  const changeHeaderHeight = () => {
    setHeaderHeight(document.getElementById("header").clientHeight * 1.2);
  };
  useEffect(() => {
    changeHeaderHeight();
  }, [screenWidth]);
  return (
    <>
      <Nav />
      <header
        id="header"
        className={`header ${className} px-xxl-5`}
        onChange={() => changeHeaderHeight()}
        style={{
          minHeight:
            !title && !introTitle && !introText && !quote ? "auto" : "",
          background: `url"("${bgImg}")`
        }}
      >
        {/* preview,
    image,
    alt,
    imageStyleClass,
    divStyleClass, */}
        {/* <AnimatePresence>
            {(bgImg || umbData.bgImg?.umbracoFile) && screenWidth > 1700 && (
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                src={(bgImg || umbData.bgImg?.umbracoFile) + "?width=500&blur=25"}
                className="header__bg--blurred"
              />
            )}
            {(bgImg || umbData.bgImg?.umbracoFile) && (
              // <LazyBlur
              //   placeholder={(bgImg || umbData.bgImg?.umbracoFile) + "?width=300"}
              //   src={
              //     (bgImg || umbData.bgImg?.umbracoFile) +
              //     "?width=2560&sharpen=10&sigma=1.5&threshold=10"
              //   }
              //   className="header__bg"
              // />
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                src={
                  (bgImg || umbData.bgImg?.umbracoFile) +
                  "?width=2560&sharpen=10&sigma=1.5&threshold=10"
                }
                className="header__bg"
              />
            )}
          </AnimatePresence> */}
        {quote && (
          <>
            {!Array.isArray(quote) && !quote.umbracoNaviHide && (
              <Quote text={quote.qText} author={quote.qAuthor} />
            )}
            {Array.isArray(quote) && quote.length > 1 && (
              <div className="my-md-5" style={{ maxWidth: "100%" }}>
                <Swiper
                  centeredSlides
                  slidesPerView={1}
                  pagination
                  keyboard
                  loop
                  fadeEffect={{ crossFade: true }}
                  effect="fade"
                  autoplay={{
                    delay: umbData?.quoteSpeed || 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                >
                  {quote.map(
                    (q) =>
                      !q.umbracoNaviHide && (
                        <SwiperSlide key={q.id}>
                          <Quote text={q.qText} author={q.qAuthor} />
                        </SwiperSlide>
                      )
                  )}
                </Swiper>
                {/* <Swiper
                    spaceBetween={50}
                    slidesPerView={3}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide>Slide 1</SwiperSlide>
                    <SwiperSlide>Slide 2</SwiperSlide>
                    <SwiperSlide>Slide 3</SwiperSlide>
                    <SwiperSlide>Slide 4</SwiperSlide>
                    ...
                  </Swiper> */}
              </div>
            )}
          </>
        )}
        <div className="header__titlebox container-xxl px-4 px-sm-5 px-xxl-0 ">
          <div className="row">
            {title && (
              <div
                className={`h1 header__titlebox__title mb-0 pb-5 ${
                  // introText ||
                  // (!horizontalMenu &&
                  //   page &&
                  //   page.children &&
                  //   page.children?.length > 0)
                  //   ? "col-lg-7"
                  //   : ""
                  ""
                  }`}
              >
                {hyphenParse(title)}
              </div>
            )}
          </div>
        </div>
      </header>
      {page && (
        <div className="container-xxl header__content px-0 px-sm-5 px-xxl-0 mt-auto">
          <div className="mb-3 mb-xl-0 px-4 px-sm-0 ">
            <div className="header__content__crumbs pt-2 ">
              {crumbs ? (
                <Breadcrumbs levels={crumbs} />
              ) : (
                <Breadcrumbs
                  levels={[{ label: page?.title, url: page?.url }]}
                />
              )}
            </div>
            <hr className="d-none d-xl-block mt-1" />
          </div>
          <div className="row">
            {(introTitle || introText) && (
              <div className="pe-xl-4 px-4 px-sm-0 order-2 mt-5 mt-xl-3">
                {introTitle || ""}
                {introText && (
                  <div className="header-introtext mb-5">
                    {introText && hyphenParse(introText)}
                  </div>
                )}
              </div>
            )}
            {/* {showLinks && page.children?.length > 0 && (
                  <div
                    className={`header-box col-xl-5 p-3
                    ${
                      !horizontalMenu && page.children?.length > 0
                        ? // && page.children?.length < 8
                          "order-xl-2"
                        : "header-box--no-nav order-1 mb-4"
                    }
                    
                    `}
                  >
                    {page && page.children?.length > 0 && (
                      <AnchorLinks items={page.children} />
                    )}
                    {subPage &&
                      subPage.children?.length > 0 &&
                      subPage.children[0]?.type !== "ansatt" &&
                      subPage.children[0]?.type !== "nyhet" && (
                        <div className="mt-3">
                          <hr />
                          <h3>Undersider</h3>
                          <AnchorLinks items={subPage.children} subPages={true} />
                        </div>
                      )}
                  </div>
                )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
