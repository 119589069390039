import React, { useContext, useEffect, useState } from "react";

// External components
import KeyboardEventHandler from "react-keyboard-event-handler";
import ReactJson from "react-json-view";

// Internal components
import AnimateMount from "~/components/AnimateMount";

// Contexts
import { DataContext } from "~/contexts/Data";

// Icons
import { FaArrowRight, FaEye } from "react-icons/fa";
import { AiOutlineMenuFold } from "react-icons/ai";

const DevMode = ({ collapsed = true }) => {
  const {
    umbData,
    path,
    showAllPages,
    setShowAllPages,
    currentPageDevData,
    seoDevData,
    devStatus,
    toggleDevStatus,
  } = useContext(DataContext);
  const [hideDevMenu, setHideDevMenu] = useState(true);
  useEffect(() => {
    let body = document.body;
    body.style.transition = "padding-left 150ms cubic-bezier(0.2, 0, 0.2, 1)";
    if (devStatus && !hideDevMenu) {
      body.style.paddingLeft =
        document.getElementById("devmodecontainer").offsetWidth / 2 + "px";
      // body.style.filter = "invert(1)";
      // body.style.background = "black";
    } else {
      body.style.paddingLeft = 0;
      // body.style.background = "white";
      // body.style.filter = "invert(0)";
    }
  }, [devStatus, hideDevMenu]);
  return (
    <>
      <KeyboardEventHandler
        handleKeys={["ctrl + shift + d"]}
        onKeyEvent={(key, e) => toggleDevStatus(!devStatus)}
      />
      {/* <OuterClick onOuterClick={() => toggleDevStatus(!false)}> */}
      {/* <div
                id="bg"
                style={{
                    position: "fixed",
                    background:
                        "url('https://media.giphy.com/media/10zxDv7Hv5RF9C/giphy.gif') center center / cover no-repeat",
                    height: "100vh",
                    width: "100vw",
                    // filter: "invert(1)",
                    top: 0,
                    left: 0,
                    transition: "all 150ms cubic-bezier(0.2, 0, 0.2, 1)",
                    maxWidth: "100vw",
                    overflow: "hidden",
                    pointerEvents: "none",
                    opacity: devStatus ? 0.1 : 0,
                }}
            ></div> */}
      {devStatus && (
        <>
          <div
            style={{
              position: "fixed",
              textAlign: "right",
              bottom: 100,
              fontSize: "2rem",
              right: 100,
              opacity: devStatus ? 1 : 0,
              pointerEvents: "none",
              zIndex: 999,
            }}
            className="btn btn-primary rounded-pill"
          >
            Devmode: on
          </div>
          <div
            id="devmodecontainer"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 999,
              minHeight: "100vh",
              background: "#ffffff",
              width: 550,
              minWidth: "25vw",
              maxHeight: "100vh",
              boxShadow: "0px 0px 50px rgba(0,0,0,0.25)",
              padding: "3rem",
              transform: devStatus && !hideDevMenu ? "" : "translateX(-200px)",
              opacity: devStatus && !hideDevMenu ? 1 : 0,
              pointerEvents: devStatus && !hideDevMenu ? "all" : "none",
              resize: "both",
              overflow: "auto",
            }}
          >
            <div className="mb-4">
              <div
                className="btn btn-dark mb-2"
                style={{ marginRight: "1rem" }}
                onClick={() =>
                  window.open(
                    `"https://www.drottningborg.vgs.no/umbraco"`,
                    "_blank"
                  )
                }
              >
                Open umbraco{" "}
                <FaArrowRight size="2rem" className="btn__icon--right" />
              </div>
              <div
                className="btn btn-dark mb-2"
                onClick={() => setShowAllPages(!showAllPages)}
              >
                Show all pages in menu
                <FaEye size="2rem" className="btn__icon--right" />
              </div>
            </div>
            <div className="mb-4">
              <h1 className="h3">Current path:</h1>
              <p>{path}</p>
            </div>
            {/* {data && (
            <ReactJson
              src={data}
              enableClipboard={false}
              indentWidth="2"
              collapsed={collapsed}
            />
          )} */}
            {umbData && (
              <>
                <p className="h3">Umbraco data: </p>
                <ReactJson
                  src={umbData}
                  enableClipboard={false}
                  indentWidth="2"
                  collapsed={collapsed}
                />
              </>
            )}
            {currentPageDevData && (
              <>
                <p className="h3 mt-4">Current page data: </p>
                <ReactJson
                  src={currentPageDevData}
                  enableClipboard={false}
                  indentWidth="2"
                  collapsed={collapsed}
                />
              </>
            )}
            {seoDevData && (
              <>
                <p className="h3 mt-4">Current page SEO: </p>
                <ReactJson
                  src={seoDevData}
                  enableClipboard={false}
                  indentWidth="2"
                  collapsed={collapsed}
                />
              </>
            )}
            {/* {currentDevData && (
            <>
              <ReactJson
                src={currentDevData}
                enableClipboard={false}
                indentWidth="2"
                collapsed={collapsed}
              />
            </>
          )} */}
            {/* <div
            className="btn btn-primary"
            style={{ position: "sticky", bottom: "100%", left: "85%" }}
            onClick={() => setCollapsed(!collapsed)}
          >
            <FaArrowDown size="2rem" />
          </div> */}
          </div>
          <AnimateMount
            trigger={devStatus}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <div
              className="btn btn-primary"
              style={{
                position: "fixed",
                top: "90%",
                left: "5rem",
                zIndex: 3000,
              }}
              onClick={() => setHideDevMenu(!hideDevMenu)}
            >
              <div
                style={{
                  transform: devStatus && !hideDevMenu ? "" : "scaleX(-1)",
                }}
              >
                <AiOutlineMenuFold size="2rem" />
              </div>
            </div>
          </AnimateMount>
        </>
      )}
    </>
  );
};

export default DevMode;
