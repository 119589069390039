import { useContext, useEffect, useState } from 'react';
import LazyBlur from "./LazyBlur";
import { DataContext } from '~/contexts/Data';

const HeaderBg = ({ src, headerHeight }) => {
    const {
        screenWidth,
    } = useContext(DataContext);
    const [size, setSize] = useState(0);
    useEffect(() => {
        let multiplier = 1.5;
        if (screenWidth >= 2560) setSize(2560 * multiplier);
        else if (screenWidth > 1800) setSize(1800 * multiplier);
        else if (screenWidth > 1600) setSize(1600 * multiplier);
        else if (screenWidth > 1400) setSize(1600 * multiplier);
        else if (screenWidth > 1200) setSize(1200 * multiplier);
        else if (screenWidth > 800) setSize(800 * multiplier);
        else if (screenWidth > 600) setSize(600 * multiplier);
        else if (screenWidth > 0) setSize(600 * multiplier);
    }, [screenWidth]);

    if (!src) return null;
    return (
        <>
            <LazyBlur
                style={{ height: headerHeight + "px" }}
                src={
                    (src) +
                    `?width=${size}&quality=80`
                    // "?width=1920&quality=70&sharpen=10&sigma=1.5&threshold=10"
                }
                key={src}
                className="header-bg"
            />
        </>
    );
};

export default HeaderBg;