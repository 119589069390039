import React, { useContext } from "react";

// External components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import LazyBlur from "~/components/LazyBlur";

// Contexts
import { DataContext } from "~/contexts/Data";

const HighlightSection = ({
  title,
  text,
  btnUrl,
  btnLabel,
  img,
  bgColor,
  variant = "right",
  containImage = false,
}) => {
  const { hyphenParse } = useContext(DataContext);
  return (
    <div
      style={{ backgroundColor: bgColor || "" }}
      className={`highlight-section highlight-section--${variant} ${!bgColor ? "highlight-section--shadow" : ""
        }`}
    >
      {img && (
        <LazyBlur
          placeholder={`${img}?width=100&height=100&mode=crop&quality=20`}
          src={`${img}?width=1200&quality=70&sharpen=10&sigma=1.5&threshold=10`}
          className="highlight-section__img"
          style={{ objectFit: containImage ? "contain" : "cover" }}
        />
      )}
      <div className="highlight-section__content-wrapper px-4 px-sm-5 py-5">
        {title && (
          <div className="highlight-section__content-wrapper__title h2 text-uppercase">
            {hyphenParse(title)}
          </div>
        )}
        {text && (
          <div className="highlight-section__content-wrapper__text">
            {hyphenParse(text)}
          </div>
        )}
        {typeof btnUrl === "string" && (
          <div>
            <InternalOrExternalLink
              to={btnUrl}
              showArrow
              className="btn btn-outline-secondary highlight-section__content-wrapper__btn rounded-pill"
            >
              {hyphenParse(btnLabel)}
            </InternalOrExternalLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default HighlightSection;
