import React from "react";

const Shrug = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200px"
      height="auto"
      viewBox="0 0 4299.357 5591.667"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1127"
            width="4299.357"
            height="5591.667"
            transform="translate(0 0.003)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_71" transform="translate(0 -0.003)">
        <g id="Group_70" clipPath="url(#clip-path)">
          <path
            id="Path_117"
            d="M738.771,2958.413s541.22-1257.446,990.447-1074.23c422.2-164.87,604.027-179.276,882.176-39.285,414.243-157.559,934.02,632.063,1077.321,1342.944,17.81,252.791-510.833,323.771-617.559,81.259-67.963-154.429-31.392-241.382-202.455-527.454l-1384.039,23.172c-98.67,249.087-133.224,306.34-310.366,521.928-101.9,91.147-383.387-117.081-435.525-328.334"
            fill="#155185"
          />
          <path
            id="Path_118"
            d="M1397.54,3007.912l-80.013,91.713c18.406-111.9,40.756-259.174,98.371-408.337-26.793,141.053-23.4,186.674-18.358,316.624"
            fill="#144573"
          />
          <path
            id="Path_119"
            d="M2514.6,1807.195c-35.731-65.567-97.617-92.315-232.682-113.4-118.668-67.928-260.766,110.18-308.647,113.4,17.866,117.913,46.864,209.3,116.183,276.113,4.287,2.5,191.106,50.65,272.931.984,69.318-66.819,134.349-159.184,152.215-277.1"
            fill="#ffaf92"
          />
          <path
            id="Path_120"
            d="M2050.447,4526.164h-.536q-31.086-1.607-62.172-3.752l-486.124-55.2c-14.325,323,89.419,862.838,118.985,1124.462h599.213L2192.479,5116.8c19.652,1.787,92.9,2.143,112.553,1.071l82.54,473.8h562.766s33.125-836.546,52.526-1129.286l-631.372,59.493a2675.324,2675.324,0,0,1-321.045,4.287"
            fill="#292a41"
          />
          <path
            id="Path_121"
            d="M2004.532,5087.859s275.131,82.182,471.652,0c0,0-217.961,39.305-471.652,0"
            fill="#1e1f31"
          />
          <path
            id="Path_122"
            d="M2192.479,5116.8l27.334,474.868h-37.518L2150.835,5116.8Z"
            fill="#1e1f31"
          />
          <path
            id="Path_123"
            d="M2079.994,2133.014c.732,4.776,2.261,9.407,1.912,14.227-24.98,345.415-17.183,1452.594,5.381,2377.338,110.042,1.188,251.812,1.789,322.978-1.681,5.835-.39,11.666-.593,17.5-1.021,0,0-63.959-2442.588-65.387-2459.025-73.178,33.928-192.893,56.43-285.431,2.355,11.3,22.315-1.319,39.381,3.044,67.807"
            fill="#ff9"
          />
          <path
            id="Path_124"
            d="M2410.265,4522.9a2673.6,2673.6,0,0,1-303.541,3.268h-.536c-6.3-.327-12.594-.782-18.89-1.14q2.358,96.612,4.925,190.231c6.621.53,13.24,1.257,19.86,1.713h5.36q158.646,9.647,308.182-6.968l2.144-188.125c-5.159.379-11.138.711-17.5,1.021"
            fill="#191927"
          />
          <path
            id="Path_125"
            d="M2427.769,4521.877c-5.838.428-11.669.631-17.505,1.021,6.366-.311,12.345-.643,17.505-1.021"
            fill="#191927"
          />
          <path
            id="Path_126"
            d="M2078.293,4524.48c3,.18,6,.374,9.005.545,0-.148-.006-.3-.01-.445q-4.538-.05-9-.1"
          />
          <path
            id="Path_127"
            d="M2044.067,4524.068c-62.8-.81-107.246-1.656-107.246-1.656s65.716-2476.373,115.769-2473.5a212.278,212.278,0,0,0,24.36,16.29c-.481-.95-.935-1.893-1.506-2.863-43.916-72.261-66.68-5.631-113.393,11.227l-39.824,2574.213-425.937,27.754,18.215,96.575c100.606,37.2,348.442,80.018,582.321,102.9-1.582-52.18-3.116-105.54-4.6-159.755-14.1-1.127-28.208-2.188-42.312-3.647Z"
            fill="#1e1f31"
          />
          <path
            id="Path_128"
            d="M1936.822,4522.412s44.441.847,107.246,1.656l-.052-1.656c11.425.788,22.851,1.389,34.277,2.068q4.461.051,9,.1c-22.564-924.744-30.362-2031.923-5.382-2377.339.349-4.82,16.526-47.8,9.75-73.669-8.332-4.868-31.229-18.492-39.065-24.654-50.053-2.878-115.769,2473.5-115.769,2473.5"
            fill="#d7d766"
          />
          <path
            id="Path_129"
            d="M2078.293,4524.48c-11.868-.133-23.331-.272-34.226-.412l5.844,187.541c14.1,1.459,28.209,2.519,42.312,3.647q-2.563-93.474-4.925-190.231c-3-.171-6-.365-9.005-.545"
            fill="#0a0a10"
          />
          <path
            id="Path_130"
            d="M1907.655,1858.72s-6.866,204.573,184,214.852c-40.406-57.705-92.016-244-16.21-305.7-79.316-58.9-167.791,90.845-167.791,90.845"
            fill="#ed987a"
          />
          <path
            id="Path_131"
            d="M2044.067,4524.068c10.895.14,22.358.278,34.226.412-11.427-.68-22.852-1.28-34.278-2.068Z"
          />
          <path
            id="Path_132"
            d="M2372.564,4771.743v103.269s289.656,14.033,557.407-76.576c5.979,185.859-14.747,793.234-14.747,793.234h35.113l40.377-954.025Z"
            fill="#1e1f31"
          />
          <path
            id="Path_133"
            d="M2009.872,2105.194s203.766,137.642,351.972,29.478c0,0-54.1,145.8-362.2,29.479Z"
            fill="#d7d766"
          />
          <path
            id="Path_134"
            d="M1973.553,1809.416c-.585-3.978-349.7,133.641-453.829,484.08-255.885,861.206-255.39,1782.914-278.5,2293.488-1.138,25.16,10.406,49.345,31.538,63.045q52.221,33.857,137.787,63.686,18.224,6.431,38.054,12.863,20.9,6.431,43.949,12.862,25.191,6.968,51.454,13.936,183.836,45.557,410.552,62.708l96.531,6.572c-47.464-1012.666-24.473-2410.124,10.434-2767.922-67.564-39.383-71.777-135.177-87.974-245.318"
            fill="#155185"
          />
          <path
            id="Path_135"
            d="M2501.786,1803.4c-17.867,117.913-79.374,217.194-143.154,290.753-54.716,372.392-110.887,1140.814-21.926,2726.214,337.111,1.781,606.171-103.544,736.138-167.244a128.772,128.772,0,0,0,71.843-121.246c-36.351-841.627-69.311-1241.67-180.99-2009.5-16.352-112.431-23.316-609.651-461.911-718.976"
            fill="#155185"
          />
          <path
            id="Path_136"
            d="M2645.667,2165.952c-116.818-110.646-280.876-21.143-287.189-27.23-18.555-17.892,49.194-182.985,151.433-179.978,104.915,3.087,151.731,174.074,135.756,207.208"
            fill="#144573"
          />
          <path
            id="Path_137"
            d="M1775.919,2174.239c107.9-115.5,273.241-33.535,279.066-39.884,17.121-18.661-58.377-180.264-158.1-172.732-102.333,7.73-138.44,180.288-120.965,212.616"
            fill="#144573"
          />
          <path
            id="Path_138"
            d="M1991.106,1729.89l-66.321,54.8c-45.58,31.769-181.228,160.183-149.33,376.626,14.084-57.644,100.241-130.45,132.838-146.211,53.342,16.856,104.014,64.544,148.957,113.006,12.65-35.262,8.9-76.712.147-100.849-22.91-63.211-67.741-134.744-30.255-219.675l32.547-48.706s-51.8-43.067-68.583-28.99"
            fill="#2874b6"
          />
          <path
            id="Path_139"
            d="M2559.26,1780.933c-28.942-42.877-72.964-38.774-91.114-40.2-5.623,98.5,13.276,183.471-74.522,288.654-40.685,47.1-40.565,82.416-40.884,107.554,62.7-66.413,107.991-78.212,160.463-87.381,62.687,39.09,94.953,65.2,135.921,116.2,44.128-212.511-89.864-384.825-89.864-384.825"
            fill="#2874b6"
          />
          <path
            id="Path_140"
            d="M3031.521,3007.359l46.789,1.539c-27.471-70.282-45.741-187.79-61.137-320.056Z"
            fill="#144573"
          />
          <path
            id="Path_141"
            d="M1482.452,1081.406q-8.061,45.813,11.364,97.76,23.614,64.423,74.238,100.491,51.386,36.959,100.146,24.275,39.084-10.273,58.082-46.912a115.594,115.594,0,0,0,7.91-20.357,134.8,134.8,0,0,0,3.7-13.361,163.965,163.965,0,0,0,3.846-30.161,178.781,178.781,0,0,0-5.427-50.026,215.734,215.734,0,0,0-7.741-24.571q-23.615-64.425-74.873-100.874-50.751-36.574-99.51-23.892-24.825,6.723-41.642,23.889-16.434,16.531-24.986,43a112.293,112.293,0,0,0-4.343,17.306,13.187,13.187,0,0,0-.767,3.436"
            fill="#f0a589"
          />
          <path
            id="Path_142"
            d="M1718.688,1150.7c-86.058-99.6-127.181-141.823-199.013-97.93,71.639.229,99.436,52.973,141.984,143.7,2.281-10.221,62.258,16.225,62.617,5.74a184.076,184.076,0,0,0-5.588-51.511"
            fill="#d98465"
          />
          <path
            id="Path_143"
            d="M2756.644,925.131a118.248,118.248,0,0,0-10.759-15.358q-18.33-22.77-40.666-32.831-22.953-10.539-49.845-7.822-52.625,5.852-88.717,60.8-36.639,55.02-35.641,127.125a226.837,226.837,0,0,0,1.623,27.027,187.956,187.956,0,0,0,13.448,51.145,172.294,172.294,0,0,0,15.1,28.165,141.359,141.359,0,0,0,8.65,11.725,121.285,121.285,0,0,0,15.407,17.016q32.4,28.836,74.624,24.251,52.625-5.848,89.2-61.416,36.157-54.406,35.159-126.511-.426-58.285-25.534-100.232a13.992,13.992,0,0,0-2.042-3.086"
            fill="#f0a589"
          />
          <path
            id="Path_144"
            d="M2565.682,1133.922c4.162,9.866,28.6-43.578,34.5-34.667.876-104.558,37.875-156.046,106.087-182.375-85.391-3.775-143.637,42.412-154.031,165.9a187.869,187.869,0,0,0,13.449,51.144"
            fill="#d98465"
          />
          <path
            id="Path_145"
            d="M1728.541,519.354c-110.279,87.575-114.613,296.193-95.2,533.2q29.7,183.738,48.435,380.691,15.814,164.372,62.544,272.607Q2288.39,2152.551,2622.1,1599q29.8-104.052,6.323-215.119-40.945-189.929-43.707-337.532-.654-22.6-2.148-47.792a54.6,54.6,0,0,1-.389-7.511,89.518,89.518,0,0,1-.649-9.64C2522.814,513.28,2371.469,493.329,2371.21,494.08c-89.922-103.853-533.941-70.315-642.669,25.274"
            fill="#ffaf92"
          />
          <path
            id="Path_146"
            d="M1635.5,1055.219c-19.134-237.036,1.942-418.81,115.772-506.816,80.307-95.455,450.575-143.111,624.729-50.387.269-.753-9.625.525-8.647-.314,103.785,71.954,164.229,237.087,202.365,491.249-6.217-92.471,40.718-224.426,14.6-370.986-13.927-78.933-29.032-148.079-66.348-203.288-40.687-68.116-131.9-112.946-210.55-146.927-73.69-31.705-153.363-34.813-243.169-30.361-88.7,4.677-170.547,36.927-249.694,75.717-83.23,40.727-95.858,59.239-146.2,127.639-135.994,166.111-46.753,526.9-32.854,614.474"
            fill="#630"
          />
          <path
            id="Path_147"
            d="M2638.338,1166.143c-11.667-9.073-57.98-157.726-63.722-266.759-13.5-4.612-21.494,8.959-19.186,4.259,40.289,180.012-2.03,397.715-69.623,452.954-251.806-256.359-661.063-16.359-683.788,143.888-100.382-67.244-133.82-196.553-159.643-390.049,21.606,143.19,27.246,194.879,23.619,328.963-89.468,471.923,458.853,535.038,600.977,537.536,510.352-236.877,433.21-598.426,371.366-810.792"
            fill="#630"
          />
          <path
            id="Path_148"
            d="M2182.813,1162.721q-2.539-2.305-4.58-4.045,42.827,53.692,3.04,105.982-32.765,43.061-85.655,47.273l4.279.266q43.864,2.743,76.026-22.1,33.2-24.24,35.006-61.717,2.342-37.445-28.116-65.663"
            fill="#d98465"
          />
          <path
            id="Path_149"
            d="M2178.233,1158.676q-28.584-23.8-68.7-26.313-43.9-2.208-77.1,22.03-32.163,24.84-34.5,62.285-1.806,37.477,27.614,65.094a111.94,111.94,0,0,0,57.838,28.319,68.712,68.712,0,0,0,12.237,1.839q52.89-4.212,85.655-47.272,39.786-52.289-3.04-105.982"
            fill="#ffaf92"
          />
          <path
            id="Path_150"
            d="M1984.241,1031.952c-96.348-89.757-232.859-6.432-230.564,78.031.384,14.1,8.914,27.026,21.583,32.4,93.543,39.643,232.46-55.171,208.981-110.426"
            fill="#fff"
          />
          <path
            id="Path_151"
            d="M1928.606,1040.858q-4.873-27.3-22.854-44.218-17.875-16.328-38.027-12.62t-31.245,25.361q-10.987,22.247-6.114,49.549t22.749,43.626q17.979,16.918,38.132,13.214t31.138-25.957q11.1-21.654,6.221-48.955"
            fill="#502800"
          />
          <path
            id="Path_152"
            d="M1830.423,1027a48.873,48.873,0,0,0-4.469,2.353q-9.66,5.719-14.377,13.951t-1.547,13.833q3.126,5.514,12.222,5.514l.283,0a32.764,32.764,0,0,0,8.318-1.177q-.258-1.26-.487-2.544a84.131,84.131,0,0,1,.057-31.928"
            fill="#fff"
          />
          <path
            id="Path_153"
            d="M1845.23,1023.545c-.094,0-.188,0-.283,0a35.372,35.372,0,0,0-14.524,3.454,84.177,84.177,0,0,0-.057,31.929q.23,1.282.487,2.543a43.311,43.311,0,0,0,10.676-4.628q9.66-5.719,14.378-13.951t1.545-13.833q-3.125-5.514-12.222-5.516"
            fill="#cdc2b6"
          />
          <path
            id="Path_154"
            d="M1984.79,1031.7c-.01-.2,0-.411-.007-.616-39.818-87.246-302.925-73.506-224.315,98.738,2.887-126.673,162.682-133.407,224.322-98.122"
            fill="#2b0e00"
          />
          <path
            id="Path_155"
            d="M2208.779,1029.714c55.044-119.623,212.972-94.382,242.925-15.373,5,13.189,2.018,28.385-7.663,38.163-71.481,72.2-236,37.24-235.262-22.79"
            fill="#fff"
          />
          <path
            id="Path_156"
            d="M2378.688,1029.421q7.934-26.571-.511-49.774-8.614-22.624-28.279-28.381t-39.315,8.588q-19.826,14.925-27.757,41.5t.682,49.2q8.443,23.2,28.108,28.96t39.488-9.167q19.652-14.346,27.584-40.92"
            fill="#502800"
          />
          <path
            id="Path_157"
            d="M2293.156,978.564a42.854,42.854,0,0,0-16.273,4.22q-8.121,4.1-8.119,9.46t8.125,9.066a35.011,35.011,0,0,0,5.392,1.931q.263-.942.545-1.89a87.58,87.58,0,0,1,10.33-22.787"
            fill="#fff"
          />
          <path
            id="Path_158"
            d="M2297.679,978.4q-.746,0-1.5.018-1.538.038-3.018.147a87.588,87.588,0,0,0-10.33,22.787q-.282.946-.545,1.89a47.4,47.4,0,0,0,12.4,1.52c.5,0,1-.006,1.506-.018a44.833,44.833,0,0,0,19.291-4.367q8.12-4.1,8.119-9.459t-8.125-9.067a42.558,42.558,0,0,0-17.788-3.451"
            fill="#b4a391"
          />
          <path
            id="Path_159"
            d="M2209.037,1029.724c-.067-.193-.161-.379-.228-.573,3.7-95.83,252.318-183.039,245.008,6.155-50.777-116.086-201.158-61.633-244.78-5.582"
            fill="#2b0e00"
          />
          <path
            id="Path_160"
            d="M2252.635,1595.029c-2.788-14.746-9.254-16.156-23.516-21.374-22.917-2.19-35.215-2.582-61.778,2.745-21.768,4.114-34.417,8.387-54.4,21.96-11.135,9.733-15.934,14.071-17.241,26.337-1.59,14.923,13.024,27.538,30.71,38.377q27.155,16.685,59.806,10.513t51.315-31.519q19.29-24.921,15.108-47.039"
            fill="#502800"
          />
          <path
            id="Path_161"
            d="M2413.236,68.056A555.679,555.679,0,0,0,2327.75,28.6c-169.82-61.209-317.58-13.383-416.1,42.726-77.79,44.305-124.89,93.729-127.885,98.606-290.882,72.817-193.84,402.7-6.223,397.723,100.795,11.984,189.762,21.485,281.55,16.518q29.286-1.584,59.137-5.36c72.52-9.047,148.561-28.188,235.024-63.3,169.855-68.98,168.31-156.245,233.192-66.835Q2629.34,197.79,2413.236,68.056"
            fill="#630"
          />
          <path
            id="Path_162"
            d="M2327.75,28.6c-169.819-61.209-317.58-13.384-416.095,42.726Q1734.166,220.576,2059.1,584.173q29.288-1.585,59.137-5.36Q1780.628-39,2327.75,28.6"
            fill="#783c00"
          />
          <path
            id="Path_163"
            d="M1939.935,842.3c-74.612,30.568-138.6,72.89-215.54,136.1-7.041-39.418-12.043-46.1-1.455-80.669,44.82-68.806,95.712-119.038,180.383-156.225Z"
            fill="#630"
          />
          <path
            id="Path_164"
            d="M2222.645,670.388S2376.9,713.3,2450.7,775.786a77.3,77.3,0,0,1,18.153,22.493c17.912,33.506,11.937,35.4,18.044,64.71-92.935-47.164-181-62.1-265.627-72.975Z"
            fill="#630"
          />
          <path
            id="Path_165"
            d="M2353.9,1488.488q3.434-54.083-63.015-67.411t-153.941,2.333q-87.491,15.661-144.79,50.634-57.788,35.063-43.313,84.932t72.351,15.3q57.876-34.574,126.545-53.927t135.944,1.393q66.785,20.834,70.219-33.252"
            fill="#ffaf92"
          />
          <path
            id="Path_166"
            d="M2293.311,1459.988a15.693,15.693,0,0,0-10.112-5.756q-166.08-24.531-285.862,57.221a15.771,15.771,0,0,0-5.844,9.622,15.085,15.085,0,0,0,2.012,11.242,15.959,15.959,0,0,0,9.709,6.333,13.686,13.686,0,0,0,11.155-2.5q110.919-75.062,264.531-52.4a15.221,15.221,0,0,0,14.411-23.766"
            fill="#d98465"
          />
          <path
            id="Path_167"
            d="M643.026,2838.02q-61.65,101.847-78.911,222.269Q546.177,3178.066,588.495,3290q42.88,112.441,144.108,182.7,106.263,74.28,235.942,63.723,123.407-7.548,199.879-102.67,77.513-95.714,100.12-216.415a667.5,667.5,0,0,0,2.2-233.786q-20.572-126.474-107.083-222.692a493.241,493.241,0,0,0-63.62-58.295c-141.118-102.347-379.174,23.871-457.018,135.457"
            fill="#2874b6"
          />
          <path
            id="Path_168"
            d="M1100.044,2702.563q-33.8-102.735-83.848-219.088-145.959-401.8-114.021-640.9a4.749,4.749,0,0,1,.422-2.165A380.1,380.1,0,0,1,914,1781.93c.224-2.828.318-5.589.457-8.373a247.245,247.245,0,0,0-1.686-45.692,139.809,139.809,0,0,0-8.659-32.238,173.165,173.165,0,0,0-20.3-39.668q-45.729-64.593-154.528-105.511-60.024-30.612-67.666-94.526-1.317-35.3-16.42-47.373-18.394-13.5-53.869,4.971-54.16,60.721,18.282,185.011-31.082.024-64.7,2.86-123.148-28.374-262.351-97.174-36.836-89.177-119.021-154-16.961-6.613-27.173-7.149-12.121-16.514-18.364-23.154-42.075-46.026-87.393-41.505-11.73,1.151-16.457,3.008a54.417,54.417,0,0,0-14.15,6.1,1169.1,1169.1,0,0,1,98.053,224.238q21.873,69.6,75.485,100.547a125.135,125.135,0,0,0,18.17,9.229l.03.171a57.648,57.648,0,0,0,14.719,29.075q46,49.289,137.806,99.952,37.5,20.006,83.09,41.195,215.682,106.589,282.242,251.553-6.76,55.018-12.057,107.284-50.507,436.764-54.512,687.258-.4,33.25-.432,63.265-1.254,188.621,26.889,311.459,46.22,201.945,171.763,226.385,74.8,5.723,136.067-21.61,78.038-34.644,132.96-121.668,121.862-190.223-10.229-593.287"
            fill="#ffaf92"
          />
          <path
            id="Path_169"
            d="M883.808,1655.96c-32.408-53.029-153.5-115.2-187.576-131.946a24.923,24.923,0,0,1-12.82-15.02c-10.22-32.939-8.706-69.942-38.218-100.445q-16.029-11.767-45.026.747a36.753,36.753,0,0,0-15.433,12.505q-42.795,60.634,24.873,176.731c155.636-20.1,237,24.282,274.2,57.428"
            fill="#f0a589"
          />
          <path
            id="Path_170"
            d="M173.538,1612.3a125.43,125.43,0,0,0,18.17,9.229Q166.1,1511.4,116.082,1354.85Z"
            fill="#f0a589"
          />
          <path
            id="Path_171"
            d="M295.21,1541.069q3.345,12.687,6.156,25.4,152.994,25.2,258.862,102.181-62.97-76.553-265.018-127.583"
            fill="#f0a589"
          />
          <path
            id="Path_172"
            d="M841.246,3439.128q74.8,5.721,136.067-21.61,78.039-34.644,132.961-121.668,121.861-190.224-10.23-593.287-33.795-102.736-83.848-219.088-145.959-401.8-114.021-640.9a4.756,4.756,0,0,1,.423-2.166,379.99,379.99,0,0,1,11.4-58.48c.224-2.828.319-5.589.457-8.373-9.949,33.782-18.944,71.534-54.675,118.382-27.827,36.483-16.878,76.239-10.512,102.029,18.812,76.229,18.483,87.253,31.087,144.215,15.8,71.42,38.7,164.968,61.073,220.878,39.908,99.738,167.617,471.791,175.6,599.049s11.787,356.926-119.727,406.622c-231.472,87.468-300.277-122.913-327.817-151.99q46.219,201.945,171.762,226.386"
            fill="#f0a589"
          />
          <path
            id="Path_173"
            d="M3735.242,3060.288q-17.264-120.419-78.911-222.27c-65.858-106.666-258.891-225.631-457.018-135.456a493.3,493.3,0,0,0-63.62,58.295q-86.514,96.219-107.084,222.693a667.532,667.532,0,0,0,2.2,233.786q22.608,120.7,100.119,216.415,76.473,95.121,199.879,102.67,129.679,10.556,235.943-63.724,101.229-70.255,144.108-182.7,42.318-111.935,24.38-229.712"
            fill="#2874b6"
          />
          <path
            id="Path_174"
            d="M4285.207,1281.415q-4.725-1.856-16.456-3.008-45.318-4.519-87.394,41.506-6.245,6.639-18.364,23.153-10.211.537-27.173,7.15-82.185,64.824-119.02,154-139.2,68.8-262.351,97.174-33.616-2.838-64.7-2.859,72.442-124.29,18.283-185.013-35.475-18.474-53.87-4.97-15.1,12.07-16.42,47.373-7.642,63.912-67.665,94.527-108.8,40.917-154.528,105.511a173.111,173.111,0,0,0-20.3,39.666,139.856,139.856,0,0,0-8.658,32.238,247.278,247.278,0,0,0-1.687,45.693c.139,2.784.234,5.545.457,8.373a380.087,380.087,0,0,1,11.4,58.48,4.768,4.768,0,0,1,.422,2.166q31.938,239.1-114.021,640.9-50.052,116.352-83.849,219.087-77.017,235.018-67.48,397.491c4.442,77.537,23.38,142.925,57.251,195.8q54.922,87.022,132.96,121.668,61.272,27.331,136.067,21.61,125.562-24.4,171.763-226.386,28.124-122.881,26.89-311.459-.034-30.016-.433-63.264-4.007-250.5-54.512-687.258-5.3-52.265-12.056-107.285,55.295-120.426,213.622-214.34c21.489-12.76,44.282-25.185,68.62-37.213q45.6-21.189,83.089-41.195,91.8-50.661,137.806-99.951a57.64,57.64,0,0,0,14.719-29.076l.03-.169a125.365,125.365,0,0,0,18.17-9.229q53.612-30.953,75.486-100.548a1169.057,1169.057,0,0,1,98.052-224.238,54.37,54.37,0,0,0-14.15-6.1"
            fill="#ffaf92"
          />
          <path
            id="Path_175"
            d="M3415.55,1655.96c32.407-53.029,153.5-115.2,187.575-131.946a24.925,24.925,0,0,0,12.821-15.02c10.22-32.939,8.706-69.942,38.217-100.445q16.031-11.767,45.027.747a36.766,36.766,0,0,1,15.433,12.505q42.8,60.634-24.873,176.731c-16.053-.486-194.256-13.638-274.2,57.428"
            fill="#f0a589"
          />
          <path
            id="Path_176"
            d="M4125.819,1612.3a125.414,125.414,0,0,1-18.17,9.229q25.608-110.132,75.627-266.682Z"
            fill="#f0a589"
          />
          <path
            id="Path_177"
            d="M4004.146,1541.069q-3.345,12.687-6.155,25.4-152.994,25.2-258.863,102.181,62.971-76.553,265.018-127.583"
            fill="#f0a589"
          />
          <path
            id="Path_178"
            d="M3322.044,3417.518q61.272,27.333,136.067,21.61,125.562-24.4,171.763-226.385,28.124-122.881,26.89-311.459-.034-30.016-.433-63.265-4.007-250.494-54.512-687.257-3.863-38.112-8.5-77.69a97.592,97.592,0,0,1,12.837-60.915q59.823-101.493,197.232-183.02c-61.661,21.9-151.455,64.331-209.937,138.606a145.576,145.576,0,0,0-29.328,111.408c19.285,130.264,67.651,488.683,56.994,741.786-13.242,314.479-62.9,453.252-82.758,473.244s-139.034,112.682-231.722,49.785c-85.929-58.308-164.625-179.131-174.8-243.913,4.442,77.537,23.38,142.925,57.251,195.8q54.922,87.024,132.96,121.668"
            fill="#f0a589"
          />
        </g>
      </g>
    </svg>
  );
};

export default Shrug;
