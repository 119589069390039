import React from "react";

import vismaLogo from "/visma-logo.svg";
const Visma = ({ size }) => {
  return (
    <img
      loading="lazy"
      style={{ width: size, height: size, objectFit: "contain" }}
      alt="visma"
      src={vismaLogo}
    />
  );
};

export default Visma;
