import React, { createContext, useState, useEffect } from "react";
import { useScrollDirection } from "react-use-scroll-direction";

// External components
import axios from "axios";
import qs from "qs";
import parse from "html-react-parser";

export const DataContext = createContext();
export const DataProvider = ({ children }) => {
  // PATHNAME
  const [path, setPath] = useState();
  const [showAllPages, setShowAllPages] = useState(false);
  const [umbData, setUmbData] = useState(null);
  const [currentDevData, setCurrentDevData] = useState(null);
  const hyphenParse = (htmlString) => {
    return <>{typeof htmlString === "string" ? parse(htmlString) : ""}</>;
  };
  useEffect(() => {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
        cache: "no-cache",
      },
      url: "/headless/data",
    })
      .then((response) => {
        // console.log(response.data.children[1].children[2].bilder);
        setUmbData(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);
  /* ********************************** */
  /*            MEDIA QUERIES           */
  /* ********************************** */
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const [isTouch, setIsTouch] = useState(false);
  useEffect(() => {
    if (window.matchMedia("(pointer: coarse)").matches) {
      // touchscreen
      setIsTouch(true);
    } else {
      setIsTouch(false);
    }
  }, []);

  const [scrolled, setScrolled] = useState(window.scrollY);
  const updateScroll = () => {
    setScrolled(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [searchMessage, setSearchMessage] = useState(null);
  const doSearch = (q) => {
    let data = qs.stringify({
      keyword: q,
    });
    let config = {
      method: "post",
      url: "/headless/search",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
        cache: "no-cache",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        let resultsArray = [];
        if (response?.data?.results?.length > 0) {
          setSearchMessage(
            `<p>Viser treff på: <strong class="text-primary"><i>${response.data.searchString}</i></strong></p>`
          );
          response.data.results.forEach((r) =>
            resultsArray.push({ id: r.id, title: r.Name, url: r.Url })
          );
          setSearchResults(resultsArray);
        } else {
          setSearchMessage(
            `<p>Ingen treff på: <strong class="text-primary"><i>${response.data.searchString}</i></strong></p>`
          );
          setSearchResults(response.data);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        setSearchMessage(`<p>${error}</p>`);
        console.error(error);
      });
  };
  const [seoDevData, setSeoDevData] = useState(null);
  const [currentPageDevData, setCurrentPageDevData] = useState(null);
  const useStickyState = (defaultValue, key) => {
    const [value, setValue] = useState(() => {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    });
    useEffect(() => {
      window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
  };

  const [devStatus, toggleDevStatus] = useStickyState(false, "devmode");
  const getTimeSince = (publishedDate) => {
    let published = new Date(publishedDate);
    let now = new Date();
    let differenceInTime = now.getTime() - published.getTime();
    let differenceInDays = (differenceInTime / (1000 * 3600 * 24)).toFixed(0);
    let differenceInMonths = (
      differenceInTime /
      (1000 * 3600 * 24 * 28)
    ).toFixed(0);
    let differenceInYears = (
      differenceInTime /
      (1000 * 3600 * 24 * 28 * 12)
    ).toFixed(0);
    function msToTime(duration, type) {
      var milliseconds = Math.floor((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      if (type === "hours") {
        return hours;
      } else if (type === "minutes") {
        return minutes;
      } else if (type === "seconds") {
        return seconds;
      } else {
        return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
      }
    }
    let hours = parseFloat(msToTime(differenceInTime, "hours")).toFixed(0);
    let minutes = parseFloat(msToTime(differenceInTime, "minutes")).toFixed(0);
    let seconds = parseFloat(msToTime(differenceInTime, "seconds")).toFixed(0);

    const DurationSince = () => {
      if (differenceInYears > 0) {
        return `${differenceInYears} år siden`;
      } else if (differenceInMonths > 0) {
        return `${differenceInMonths} måned${differenceInMonths > 1 ? "er" : ""
          } siden`;
      } else if (differenceInDays > 0 && differenceInDays < 28) {
        return `${differenceInDays} dag${differenceInDays > 1 ? "er" : ""
          } siden`;
      } else if (hours > 0) {
        return hours + ` time${hours > 1 ? "r" : ""} siden`;
      } else if (minutes > 0) {
        return minutes + ` minutt${minutes > 1 ? "er" : ""} siden`;
      } else if (seconds > 0) {
        return seconds + ` sekund${seconds > 1 ? "er" : ""} siden`;
      }
      // else if (hours > 0 && differenceInDays <= 0) {
      //   return (
      //     <>{`${parseFloat(hours).toFixed()} time${
      //       hours > 1 ? "r" : ""
      //     } siden`}</>
      //   );
      // } else if (minutes > 0 && differenceInDays <= 0 && hours >= 0) {
      //   return (
      //     <>
      //       {`${parseFloat(minutes).toFixed()} minutt${
      //         minutes > 1 ? "er" : ""
      //       } siden`}
      //     </>
      //   );
      // }
      else {
        return "";
      }
    };
    // return published.toLocaleDateString("nb-NO", options);
    return (
      <>
        <DurationSince />
      </>
    );
  };
  const [scrollDirection, setScrollDirection] = useState();
  const { isScrollingUp, isScrollingDown } = useScrollDirection();

  React.useEffect(() => {
    isScrollingDown && setScrollDirection("down");
    isScrollingUp && setScrollDirection("up");
  }, [isScrollingDown, isScrollingUp]);

  const [headerBgImg, setHeaderBgImg] = useState("");
  const [headerHeight, setHeaderHeight] = useState("");
  const [mobileMenu, setMobileMenu] = useState(false);
  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };
  const [routes, setRoutes] = React.useState();
  const [currentRoute, setCurrentRoute] = React.useState();


  // const [noMoreRoutes, setNoMoreRoutes] = useState(false);
  const evaluateChildNodes = React.useCallback((node) => {
    // If children of root has children
    if (node.children?.length > 0) {
      // Update rooutes by adding children
      setRoutes((routes) => [
        ...routes,
        ...node.children.map((child) => addValuesToChild(node, child)),
      ]);
      // Run loop function on children
      node.children.forEach((singleNode) => {
        evaluateChildNodes(singleNode);
      });
    } else {
      // No children, done with loop
      // setNoMoreRoutes(true);
    }
  }, []);
  const addValuesToChild = (parent, child) => {
    let obj = { parentKey: parent.key };
    // if (child.type.toLowerCase() === "avdeling") {
    //     obj["ansatteny"] = child?.ansatte?.map((ansatt) => {
    //         return {
    //             ...ansatt,
    //             url: ansatt.url.replace(/(ansatte)/, `$1/${child.slug}`),
    //         };
    //     });
    // }
    return { ...child, ...obj };
  };
  useEffect(() => {
    if (umbData?.children) {
      setRoutes(
        (routes) =>
          Array.isArray(routes) // If routes is array
            ? [
              ...routes,
              ...umbData.children.map((child) =>
                addValuesToChild(umbData, child)
              ),
            ] // Merge both routes and children into new array
            : [
              umbData,
              ...umbData.children.map((child) =>
                addValuesToChild(umbData, child)
              ),
            ] // If routes is not array, initiate array with root and children
      );

      // Run loop function on children
      umbData.children.forEach((singleNode) => {
        evaluateChildNodes(singleNode);
      });
    } else {
      setRoutes(umbData);
    }
  }, [umbData, evaluateChildNodes]);
  return (
    <DataContext.Provider
      value={{
        screenWidth,
        screenHeight,
        path,
        setPath,
        umbData,
        isTouch,
        currentDevData,
        setCurrentDevData,
        parse,
        hyphenParse,
        showAllPages,
        setShowAllPages,
        searchQuery,
        setSearchQuery,
        searchMessage,
        setSearchMessage,
        doSearch,
        searchResults,
        setSearchResults,
        seoDevData,
        setSeoDevData,
        currentPageDevData,
        setCurrentPageDevData,
        scrolled,
        setScrolled,
        devStatus,
        toggleDevStatus,
        getTimeSince,
        scrollDirection,
        headerBgImg,
        setHeaderBgImg,
        headerHeight,
        setHeaderHeight,
        mobileMenu,
        setMobileMenu,
        groupBy,
        routes,
        currentRoute,
        setCurrentRoute
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
