import React, { useState, useRef, useEffect } from "react";

// External components
import FadeIn from "react-fade-in";
import { FileIcon, defaultStyles } from "react-file-icon";
import { FaSearch } from "react-icons/fa";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FcSearch } from "react-icons/fc";

// Internal components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import AnimateMount from "~/components/AnimateMount";
import M from "~/components/Message";

const Files = ({ items, limit = 10, c }) => {
  let fileSearchBox = useRef(null);
  const [fileSearchQuery, setFileSearchQuery] = useState("");
  const [amountLimit, setAmountLimit] = useState(limit);
  const [showFiles, setShowFiles] = useState(true);
  const [allFiles, setAllFiles] = useState([]);
  const [visible, setVisible] = useState(allFiles);

  const getItemsAndChildren = (arr) => {
    let array = [];
    if (Array.isArray(arr) && arr.length > 0) {
      arr.forEach((item) =>
        item.type === "folder"
          ? item.children.forEach((child) => {
            if (child.type === "folder") {
              if (child.children) {
                array.push(...getItemsAndChildren(child.children));
              }
            } else {
              array.push(child);
            }
          })
          : array.push(item)
      );
    }
    return array;
  };

  useEffect(() => {
    if (items) {
      setAllFiles((allFiles) => [...allFiles, ...getItemsAndChildren(items)]);
    }
    // eslint-disable-next-line
  }, [items]);

  useEffect(() => {
    if (allFiles) {
      if (fileSearchQuery.length > 0) {
        let filtered = allFiles.filter((item) =>
          JSON.stringify(item)
            .toLowerCase()
            .includes(fileSearchQuery.toLowerCase())
            ? true
            : false
        );
        if (filtered.length > 0) {
          setVisible(filtered);
        } else {
          setVisible([]);
        }
      } else {
        setVisible(allFiles);
      }
    }
  }, [fileSearchQuery, allFiles]);
  return (
    <div className="w-100 files mb-5 px-3" id="files">
      {c.title && <h2>{c.title}</h2>}
      {items?.length > 11 && (
        <div className="files__controls">
          <label
            className={`btn btn--outline-subtle-grey label files__controls__search`}
            style={{ paddingLeft: "1rem" }}
          >
            <input
              placeholder={"Søk"}
              type="search"
              id={items[0].id + "search"}
              ref={fileSearchBox}
              onChange={(e) => {
                setFileSearchQuery(e.target.value);
              }}
              //   onKeyPress={(e) => {}}
              style={{
                cursor: "text",
                width: fileSearchQuery.length > 0 ? "100%" : "80%",
              }}
            />
            {!fileSearchBox.current?.value && (
              <FadeIn transitionDuration="150">
                <FaSearch size="1.5rem" className="btn__icon" />
              </FadeIn>
            )}
          </label>
          <div className="files__controls__buttons">
            {amountLimit < visible?.length && (
              <label
                className="btn px-0 no-underline files__controls__buttons__show-only"
                style={{ fontWeight: "normal" }}
              >
                <span>Vis kun</span>
                <input
                  type="number"
                  min={0}
                  id={items[0].id + "limiter"}
                  value={
                    items.length > amountLimit ? amountLimit : items.length
                  }
                  max={items.length}
                  style={{ border: "1px solid #ccc", fontWeight: "bold" }}
                  onChange={(e) => setAmountLimit(e.target.value)}
                  className="bg-white rounded text-center"
                />

                <div> av {visible?.length} filer</div>
              </label>
            )}
            {!showFiles && (
              <div
                className="btn btn--primary-light mt-3"
                onClick={() => setShowFiles(true)}
              >
                <BsChevronDown size="2rem" /> Vis filer
              </div>
            )}
            {showFiles && (
              <div
                className="btn btn--light-red mt-3"
                onClick={() => setShowFiles(false)}
              >
                <BsChevronUp size="2rem" /> Skjul filer
              </div>
            )}
          </div>
        </div>
      )}
      {showFiles && (
        <>
          <AnimateMount trigger={true}>
            <div className="files__grid py-4" delay={0.01}>
              {visible?.map(
                (item, index) =>
                  index < amountLimit && (
                    <InternalOrExternalLink
                      to={item.umbracoFile}
                      className="files__grid__item"
                      download={item.umbracoFile}
                    >
                      <span className="files__grid__item__icon">
                        {item.umbracoExtension && (
                          <FileIcon
                            extension={item.umbracoExtension?.toLowerCase()}
                            {...defaultStyles[
                            item.umbracoExtension?.toLowerCase()
                            ]}
                          />
                        )}
                      </span>
                      <p className="files__grid__item__label">
                        {item.title}
                        <pre className="mt-2">
                          {item.umbracoFile?.split("/")[3]}
                        </pre>
                      </p>
                    </InternalOrExternalLink>
                  )
              )}
            </div>
            <div className="text-center w-100 mb-4">
              <M trigger={visible?.length === 0 && fileSearchQuery.length > 0}>
                <FcSearch size="2rem" className="me-3" />
                <strong>Ingen resultater på {fileSearchQuery}</strong>
              </M>
            </div>
            <div>
              <div className="d-flex justify-content-center">
                {items?.length > amountLimit && (
                  <div
                    className="btn btn--primary-light me-3"
                    onClick={() => {
                      setAmountLimit(visible?.length);
                      document.getElementById(items[0].id + "search").value =
                        "";
                      setFileSearchQuery("");
                    }}
                  >
                    Last inn alle
                  </div>
                )}
                {visible?.length === amountLimit && (
                  <div
                    className="btn btn--light-red"
                    onClick={() => {
                      setAmountLimit(limit);
                      document
                        .getElementById("files")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Vis kun {limit}
                  </div>
                )}
              </div>
            </div>
          </AnimateMount>
        </>
      )}
    </div>
  );
};

export default Files;
