import React, { useContext } from "react";

// External components
import { BiHomeAlt } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";

// Internal components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";

// Contexts
import { DataContext } from "~/contexts/Data";

const Breadcrumbs = ({ levels }) => {
  const { hyphenParse } = useContext(DataContext);
  return (
    <div className="breadcrumbs">
      <InternalOrExternalLink
        to="/"
        style={{ color: "inherit" }}
        className="btn p-1 btn--transparent"
      >
        <BiHomeAlt />
      </InternalOrExternalLink>
      {Array.isArray(levels) &&
        levels.length > 0 &&
        levels.map((item) => (
          <span key={item.url + "breadcrumb"}>
            <FiChevronRight color="#BABCC9" />
            <InternalOrExternalLink
              to={item.url}
              className="rounded p-1 btn--transparent breadcrumbs__item"
            >
              {hyphenParse(item.label)}
            </InternalOrExternalLink>
          </span>
        ))}
    </div>
  );
};

export default Breadcrumbs;
