import React, { useContext } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import AnimateMount from "~/components/AnimateMount";
import Json from "~/components/Json";

const Section = ({ title, children, devData, className, style }) => {
  const { devStatus } = useContext(DataContext);
  return (
    <section
      style={Object.assign(
        { background: devStatus ? "rgba(150,150,150,0.2)" : "" },
        style
      )}
      className={`${devStatus ? "p-3 mb-5" : ""} ${className}`}
    >
      <AnimateMount trigger={devStatus}>
        {title && <h3>{title}</h3>}
        <Json obj={devData} />
      </AnimateMount>
      {children}
    </section>
  );
};

export default Section;
