import React, { useContext } from "react";

// External components
import FadeIn from "react-fade-in";
import {
  BsFillPhoneVibrateFill,
  BsFillEnvelopeFill,
  BsLink45Deg,
  BsFillChatLeftQuoteFill,
} from "react-icons/bs";
import { ImBooks } from "react-icons/im";

// Internal components
import LazyBlur from "~/components/LazyBlur";

// Contexts
import { DataContext } from "~/contexts/Data";

const Ansatt = ({ content }) => {
  const { hyphenParse } = useContext(DataContext);
  // content = {
  //   id: 3792,
  //   key: "d308d86b-c209-484c-af63-c9877ace6181",
  //   title: "Bakken",
  //   slug: "bakken",
  //   url: "/om-oss/alle-ansatte/bakken",
  //   type: "ansatt",
  //   children: [],
  //   seoTitle: "",
  //   seoDescription: "",
  //   seoImage: null,
  //   umbracoNaviHide: false,
  //   bilde: {
  //     id: 2693,
  //     key: "4384d6bf-ce7c-4d15-b05e-f9e94feaac16",
  //     title: "Bakken",
  //     slug: null,
  //     url: "/media/xhliiq12/ruth-silje-bakken.jpg?anchor=center&mode=crop&width=1200&rnd=132729928492770000",
  //     type: "mediaWithCrops`1",
  //     children: [],
  //     umbracoFile: "/media/xhliiq12/ruth-silje-bakken.jpg",
  //     umbracoWidth: "4000",
  //     umbracoHeight: "6016",
  //     umbracoBytes: "4043226",
  //     umbracoExtension: "jpg",
  //   },
  //   fornavn: "Ruth Silje",
  //   etternavn: "Bakken",
  //   stilling: "Kokk",
  //   fodselsdato: "12.09.1993",
  //   telefonJobb: "12345678",
  //   telefonHjem: "12345678",
  //   mobiltelefon: "12345678",
  //   epost: "ruth@ruth.no",
  //   hjemmeside: "ruth.no",
  //   fag: "gym, matte, naturfag",
  //   kommentar: "Jeg elsker livet",
  // };
  return (
    <div className="ansatt">
      {content.bilde?.url && (
        <FadeIn>
          <LazyBlur
            className="rounded ansatt__img"
            src={content.bilde.url + "&width=200"}
            palceholder={content.bilde.url + "&width=50"}
            key={content.bilde.key}
            alt={content.bilde.title}
          />
        </FadeIn>
      )}
      <div className="ansatt__content">
        {(content.fornavn || content.etternavn) && (
          <>
            <h3 className="ansatt__content__navn">
              {hyphenParse(content.fornavn + " " + content.etternavn)}
            </h3>
          </>
        )}
        {content.stilling && (
          <div className="ansatt__content__stilling">
            {hyphenParse(content.stilling)}
          </div>
        )}
        {content.fodselsdato && (
          <div className="ansatt__content__fodselsdato">
            Bursdag: {hyphenParse(content.fodselsdato)}
          </div>
        )}
        <hr />
        {content.fag && (
          <div className="ansatt__content__fag">
            <ImBooks size="1.5rem" className="me-3" />
            Fag: {hyphenParse(content.fag)}
            <hr />
          </div>
        )}
        {(content.mobiltelefon ||
          content.telefonJobb ||
          content.telefonHjem) && (
            <>
              {content.mobiltelefon && (
                <div className="ansatt__content__mobiltelefon">
                  <BsFillPhoneVibrateFill size="1.5rem" className="me-3" /> Mobil:{" "}
                  {hyphenParse(content.mobiltelefon)}
                </div>
              )}
              {content.telefonJobb && (
                <div className="ansatt__content__telefonjobb">
                  <BsFillPhoneVibrateFill size="1.5rem" className="me-3" />{" "}
                  Jobbtelefon: {hyphenParse(content.telefonJobb)}
                </div>
              )}
              {content.telefonHjem && (
                <div className="ansatt__content__telefonHjem">
                  <BsFillPhoneVibrateFill size="1.5rem" className="me-3" />{" "}
                  Hjemmetelefon: {hyphenParse(content.telefonHjem)}
                </div>
              )}
              <hr />
            </>
          )}
        {content.epost && (
          <div className="ansatt__content__epost">
            <BsFillEnvelopeFill size="1.5rem" className="me-3" />
            {hyphenParse(content.epost)}
          </div>
        )}
        {content.hjemmeside && (
          <div className="ansatt__content__hjemmeside">
            <BsLink45Deg size="1.5rem" className="me-3" />
            {hyphenParse(content.hjemmeside)}
          </div>
        )}
      </div>
      {content.kommentar && (
        <blockquote className="ansatt__content__kommentar mt-3">
          <BsFillChatLeftQuoteFill size="2rem" className="me-3" />
          <em>{hyphenParse(content.kommentar)}</em>
        </blockquote>
      )}
    </div>
  );
};

export default Ansatt;
