import React, { useContext, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Contexts
import { DataContext } from "~/contexts/Data";

// External components
import { BsPlus } from "react-icons/bs";
// import { HiHeart } from "react-icons/hi";

// Internal components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import BgVideo from "~/components/BgVideo";

// Routes
import Home from "~/routes/Home";
import NotFound from "~/routes/NotFound";
import SearchResults from "~/routes/SearchResults";
import Page from "~/routes/Page";
import SubPage from "~/routes/SubPage";
import SubSubPage from "~/routes/SubSubPage";
import HeaderBg from "./HeaderBg";
import LazyBlur from "./LazyBlur";

const AllRoutes = () => {
  const {
    umbData,
    setPath,
    path,
    screenWidth,
    headerBgImg,
    headerHeight,
    scrollDirection,
    mobileMenu,
    routes
  } = useContext(DataContext);
  const { pathname } = useLocation();
  useEffect(() => {
    if (path !== pathname) {
      setPath(pathname);
    }
  });
  return (
    <>
      {/* <Nav /> */}
      <Routes>
        <>
          {/* SEARCH page */}
          <Route path="/sok" exact element={
            <SearchResults />}>
          </Route>

          {/* PAGE LEVEL 2 - e.g /aktuelt/nyhetsarkiv/fylkesmesse */}
          {umbData.children?.map((page) =>
            page.children?.map((subPage) =>
              subPage.children?.map((subSubPage) => (
                <Route path={subSubPage.url} exact key={subSubPage.url + "route"} element={
                  <SubSubPage
                    page={page}
                    subPage={subPage}
                    subSubPage={subSubPage}
                    tabTitle={`${page.title.toLowerCase()} - ${subPage.title.toLowerCase()} - ${subSubPage.title.toLowerCase()}`}
                  />}>
                </Route>
              ))
            )
          )}

          {/* PAGE LEVEL 2 - e.g /aktuelt/nyhetsarkiv */}
          {umbData.children?.map((page) =>
            page.children?.map((subPage) => (
              <Route path={subPage.url} exact key={subPage.url + "route"} element={
                <>
                  <SubPage
                    page={page}
                    subPage={subPage}
                    tabTitle={`${page.title.toLowerCase()} - ${subPage.title.toLowerCase()}`}
                  />
                </>
              }
              >
              </Route>
            ))
          )}

          {/* PAGE LEVEL 1 - e.g /aktuelt */}
          {umbData?.children && umbData?.children?.map((page) => (
            <Route path={page.url} exact key={page.url + "route"} element={
              <Page page={page} tabTitle={page.title} />
            }>
            </Route>
          ))}

          {/* PAGE LEVEL  - e.g / */}
          {umbData && (
            <Route path="/" exact element={
              <Home />}>
            </Route>
          )}

          {/* 404 page */}
          <Route path="*" element={<NotFound />}>
          </Route>
        </>
      </Routes>
      {umbData && (pathname === "/" || mobileMenu) && (
        <div
          className={`nav__bottom-bar d-lg-none ${scrollDirection === "down" && !mobileMenu ? "hide" : "show"
            }`}
        >
          {umbData.sokNa?.url && (
            <InternalOrExternalLink
              to={umbData.sokNa.url}
              className="btn btn--blue-gradient px-3 py-2"
            >
              {umbData.sokNa.label}
              <BsPlus size="3rem" className="btn__icon btn__icon--lg ms-2" />
            </InternalOrExternalLink>
          )}
          {/* {umbData.give?.url && (
            <InternalOrExternalLink
              to={umbData.give?.url}
              className="btn btn--light-red px-3 py-2"
            >
              <HiHeart size="1.5rem" className="me-2" />
              Gi
            </InternalOrExternalLink>
          )} */}
        </div>
      )}
      {umbData ? (
        <>
          {umbData.bgVideo && pathname === "/" ? (
            <BgVideo c={umbData.bgVideo} />
          ) : (
            <>
              {(headerBgImg || umbData.bgImg?.umbracoFile) &&
                screenWidth > 1700 && (
                  <LazyBlur
                    alt="header blurred"
                    style={{ height: headerHeight + "px" }}
                    src={
                      (headerBgImg || umbData.bgImg?.umbracoFile) +
                      "?width=700&blur=50"
                    }
                    key={
                      (headerBgImg || umbData.bgImg?.umbracoFile) + "blurred"
                    }
                    className="header-bg--blurred"
                  />
                )}

              <HeaderBg src={headerBgImg || umbData.bgImg?.umbracoFile} headerHeight={headerHeight} />
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default AllRoutes;
