import React from "react";

// External components
import ReactJson from "react-json-view";

const Json = ({ obj, collapsed = true }) => {
  let key = typeof obj;
  return (
    <>
      {obj && (
        <div className="py-4 d-flex justify-content-center align-items-center w-100">
          <div className="w-100">
            <>
              <ReactJson
                style={{
                  backgroundColor: "rgba(200,200,200,0.2)",
                  padding: "1rem",
                }}
                src={typeof obj === "object" ? obj : { [key]: obj }}
                enableClipboard={false}
                collapsed={collapsed}
              />
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default Json;
