import React, { useState, useEffect, useContext } from "react";

// External components
import FadeIn from "react-fade-in";
import AnimateHeight from "react-animate-height";

// Internal components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import LazyBlur from "~/components/LazyBlur";
import SearchBox from "~/components/SearchBox";

// Contexts
import { DataContext } from "~/contexts/Data";

const Ansatte = ({ people }) => {
  const [searchMessage, setSearchMessage] = useState(() => { });
  const { hyphenParse, groupBy } = useContext(DataContext);
  // let fileSearchBox = useRef(null);

  const [visible, setVisible] = useState(people);
  const [categories, setCategories] = useState();
  const [sortBy, setSortBy] = useState("avdeling");
  useEffect(() => {
    if (visible && sortBy !== undefined) {
      let grouped = groupBy(visible, `${sortBy}`);
      setCategories(grouped);
    } // eslint-disable-next-line
  }, [visible, sortBy]);
  let sortProperties = { stilling: "", fag: "", avdeling: "" };
  return (
    <div className="w-100 ansatte">
      <div className="mb-3">
        <div className="ansatte__controls mb-4">
          {people && (
            <SearchBox
              returnSearchMessage={setSearchMessage}
              items={people}
              limitToKeys={["navn", "stilling"]}
              placeholder="Navn eller stilling"
              returnResults={setVisible}
            />
          )}
          <div className="ansatte__controls__buttons d-flex mt-3 mt-md-0">
            <label htmlFor="sorter-etter" style={{ width: 200 }}>
              <p className="mb-1">Grupper etter</p>
              <select
                name="sorter-etter"
                className="form-select"
                onChange={(e) => setSortBy(e.target.value)}
                defaultValue="avdeling"
              >
                {Object.keys(sortProperties).map((key) => (
                  <option value={key} key={`grupper etter ${key}`}>
                    {key}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <AnimateHeight
          duration={300}
          easing="cubic-bezier(0.16, 1, 0.3, 1)"
          height={searchMessage ? "auto" : 0}
        >
          <div style={{ height: "maxContent" }}>
            <div className="pb-4">{searchMessage}</div>
          </div>
        </AnimateHeight>
      </div>
      {categories &&
        Object.keys(categories)
          .sort(function (a, b) {
            return (a === "") - (b === "") || +(a > b) || -(a < b);
          })
          .map((key, index) => (
            <div key={key + "ansatte-gruppe"}>
              {Object.keys(categories).length > 0 && (
                <p className="hr-title h4 mb-0">
                  {key !== "undefined" && key !== "null" && key !== ""
                    ? key
                    : "Uspesifisert"}
                </p>
              )}
              <div className="ansatte__grid py-4" delay={0.01}>
                {categories[key].map(
                  (item, index) =>
                    !item.umbracoNaviHide && (
                      <InternalOrExternalLink
                        key={item.fornavn + " " + item.etternavn + "ansatt"}
                        to={item.url}
                        className="ansatte__grid__item rounded"
                      >
                        {item.bilde?.url && (
                          <FadeIn>
                            <LazyBlur
                              className="ansatte__grid__item__img rounded"
                              src={item.bilde.url + "&width=200"}
                              palceholder={item.bilde.url + "&width=50"}
                              key={item.bilde.key}
                              alt={item.bilde.title}
                            />
                          </FadeIn>
                        )}
                        <div>
                          <div className="ansatte__grid__item__navn">
                            {hyphenParse(item.fornavn + " " + item.etternavn)}
                          </div>
                          {item.stilling && (
                            <div className="ansatte__grid__item__stilling">
                              {hyphenParse(item.stilling)}
                            </div>
                          )}
                        </div>
                      </InternalOrExternalLink>
                    )
                )}
              </div>
            </div>
          ))}
    </div>
  );
};

export default Ansatte;
