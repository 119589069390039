/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import LazyBlur from "~/components/LazyBlur";
import AnimateMount from "~/components/AnimateMount";

const NewsGrid = ({
  items,
  icon = null,
  showUrl = false,
  minHeight = "",
  limit = undefined,
  hideText,
}) => {
  const { hyphenParse } = useContext(DataContext);
  const hasImage = (itemToCheck) => {
    let bilder = itemToCheck.bilder;
    if (bilder && Array.isArray(bilder) && bilder.length > 0) {
      if (bilder[0]?.umbracoFile) {
        return itemToCheck.bilder[0]?.umbracoFile;
      }
    } else if (
      itemToCheck.seoImage?.umbracoFile ||
      itemToCheck.img?.umbracoFile
    ) {
      return itemToCheck.seoImage?.umbracoFile || itemToCheck.img?.umbracoFile;
    } else {
      return false;
    }
  };
  return (
    <>
      {items && items.length > 0 && (
        <div className={`news-grid news-grid--${items.length}-col`}>
          {items.map(
            (item, index) =>
              (item.url || item.linkTo?.url) &&
              (limit ? index < limit : true) && (
                <InternalOrExternalLink
                  key={item.id + "news-grid-item"}
                  to={item.url || item.linkTo?.url}
                  style={{ minHeight: minHeight }}
                  className={`news-grid__item ${hasImage(item) ? "" : "news-grid__item--no-bg"
                    }`}
                >
                  {hasImage(item) && (
                    <LazyBlur
                      placeholder={`${hasImage(
                        item
                      )}?width=50&height=50&mode=crop&quality=20`}
                      src={`${hasImage(item)}?width=${items.length > 3 ? 600 : 1000
                        }&height=${items.length > 3 ? 600 : 1000
                        }&mode=crop&quality=60${items.length <= 3
                          ? "&sharpen=10&sigma=1.5&threshold=10"
                          : ""
                        }`}
                      className="news-grid__item__bg"
                    />
                  )}
                  {/* {typeof item.dato === "string" &&
                    item.type &&
                    item.type?.toLowerCase() === "nyhet" && (
                      <p className={`news-grid__item__date`}>
                        {item.dato && getTimeSince(item.dato)}
                      </p>
                    )} */}
                  {!hideText &&
                    typeof (item.intro || item.bodyText) === "string" &&
                    item.type &&
                    item.type?.toLowerCase() !== "nyhet" && (
                      <p className={`news-grid__item__text`}>
                        {hyphenParse(item.intro || item.bodyText)}

                        {/* <pre>{item.dato}</pre> */}
                      </p>
                    )}
                  <div
                    className={`news-grid__item__overlay`}
                    style={{
                      opacity: hasImage(item) ? "1" : "0", // add overlay if background image exists
                    }}
                  ></div>
                  {/* <img loading="lazy" src={item.bilder[0]?.url} /> */}
                  {/* {JSON.stringify(item.bilder[0])} */}
                  <div className={`news-grid__item__textwrapper`}>
                    <div>
                      {typeof item.title === "string" && (
                        <div className="news-grid__item__textwrapper__title">
                          {hyphenParse(item.title)}
                        </div>
                      )}
                      {typeof item.url === "string" && (
                        <AnimateMount trigger={showUrl}>
                          <p className="small news-grid__item__textwrapper__url mt-2">
                            {hyphenParse(item.url || item.linkTo?.url)}
                          </p>
                        </AnimateMount>
                      )}
                    </div>
                    {icon ? (
                      <div className="news-grid__item__textwrapper__arrow-right">
                        {icon}
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33.763"
                        height="59.201"
                        viewBox="0 0 33.763 59.201"
                        className={`news-grid__item__textwrapper__arrow-right`}
                      >
                        <path
                          id="Path_358"
                          data-name="Path 358"
                          d="M5217.978,1504.771l26.521,25.359-26.521,25.358"
                          transform="translate(-5213.736 -1500.529)"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </InternalOrExternalLink>
              )
          )}
        </div>
      )}
    </>
  );
};

export default NewsGrid;
