import React, { useContext, useState } from "react";

// External components
import ReactPlayer from "react-player";
import { IoPlay } from "react-icons/io5";

// Contexts
import { DataContext } from "~/contexts/Data";
import { FcVideoProjector } from "react-icons/fc";
import { MdReplay } from "react-icons/md";

const VideoSection = ({ url, text }) => {
  const { hyphenParse } = useContext(DataContext);
  const [playing, setPlaying] = useState(false);
  const [ended, setEnded] = useState(false);
  return (
    <div className="video-section">
      <div className="video-section__content ps-4 ps-md-5 pe-3">
        <FcVideoProjector size="4rem" className="mb-2" />
        {text && hyphenParse(text)}
      </div>
      <div className="px-4 px-sm-5">
        <div className="video-section__player-wrapper">
          <div
            className={`video-section__player-wrapper__icons ${playing ? "video-section__player-wrapper__icons--playing" : ""
              }`}
            onClick={() => {
              setPlaying(!playing);
            }}
          >
            {ended ? (
              <MdReplay
                size="5rem"
                color="white"
                className="video-section__player-wrapper__icons--rewind"
              />
            ) : (
              <IoPlay size="5rem" color="white" />
            )}
          </div>
          {url && (
            <ReactPlayer
              url={url}
              playing={playing}
              className="video-section__player-wrapper__react-player"
              width="100%"
              height="100%"
              controls={true}
              onPause={() => setPlaying(false)}
              onPlay={() => setEnded(false)}
              onEnded={() => {
                setEnded(true);
                setPlaying(false);
              }}
              pip
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
