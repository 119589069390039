import { createRoot } from "react-dom/client";
import "~/sass/main.scss";
import App from "./App";
import { DataProvider } from "~/contexts/Data";
import SimpleReactLightbox from "simple-react-lightbox";
import { BrowserRouter as Router } from "react-router-dom";
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <DataProvider>
    <SimpleReactLightbox>
      <Router>
        <App />
      </Router>
    </SimpleReactLightbox>
  </DataProvider>
);
