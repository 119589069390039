import React from "react";
import AnimateMount from "~/components/AnimateMount";

const Message = ({
  children,
  trigger,
  red = false,
  green = false,
  delay,
  className,
  isBtn = false,
  ...rest
}) => (
  <AnimateMount trigger={trigger} delay={delay}>
    <div
      {...rest}
      className={`${isBtn ? "" : "message"} ${className}`}
      style={
        red || green
          ? {
            background: `${red ? "#F8DDDD" : ""} ${green ? "#E2F8DD" : ""}`,
            color: `${red ? "red" : ""} ${green ? "green" : ""}`,
          }
          : {}
      }
    >
      {children}
    </div>
  </AnimateMount>
);

export default Message;
