import React, { useContext } from "react";

// Internal components
import Collapse from "~/components/Collapse";
import LazyBlur from "~/components/LazyBlur";

// Contexts
import { DataContext } from "~/contexts/Data";
import { BiChevronDown } from "react-icons/bi";

const Faq = ({ items, bgImg, title, isGroup }) => {
  const { hyphenParse, screenWidth } = useContext(DataContext);
  return (
    <Collapse
      open={screenWidth > 900 ? true : false}
      className="faq"
      header={
        <div className="faq__header h3 mb-0">
          <LazyBlur
            src={bgImg + "?width=1400&sharpen=10&sigma=1.5&threshold=10"}
            placeholder={bgImg + "?width=300&quality=40"}
            className="faq__header__bg"
          />
          <div className="faq__header__title">{hyphenParse(title)}</div>
          <BiChevronDown size="4rem" className="faq__header__icon" />
        </div>
      }
    >
      <div className="row pt-4 pb-5">
        {Array.isArray(items) &&
          items.length > 0 &&
          items.map((item) => (
            <Collapse
              className={`faq__item px-sm-0 ${isGroup ? "col-md-6 col-lg-12" : "col-md-6"
                }`}
              openClass="faq__item--open"
              header={
                <div className="faq__item__question btn btn--primary-light rounded-0 w-100">
                  <span className="faq__item__question__text">
                    {hyphenParse(item.question)}
                  </span>
                  <BiChevronDown
                    size="2rem"
                    className="btn__icon btn__icon--lg btn__icon--right"
                  />
                </div>
              }
            >
              <div className="faq__item__answer w-100">
                {hyphenParse(item.answer)}
              </div>
            </Collapse>
          ))}
      </div>
    </Collapse>
  );
};

export default Faq;
