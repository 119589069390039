import React from "react";
import { Link } from "react-router-dom";

const InternalOrExternalLink = ({
  children,
  to,
  target,
  rel,
  routePrefix = "",
  style,
  className,
  download,
  showArrow,
  arrowClassName,
  onClick,
}) => {
  if (to !== undefined) {
    if (to === "") {
      return (
        <div style={style} className={className}>
          {children}
        </div>
      );
    } else if (to.startsWith("/") && !download) {
      return (
        <Link
          to={routePrefix ? routePrefix + to : to}
          className={className}
          style={style}
          onClick={onClick}
        >
          {children}
          {showArrow && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.943"
              height="32.773"
              viewBox="0 0 19.943 32.773"
              style={{ marginLeft: "1rem" }}
            >
              <path
                id="Path_347"
                data-name="Path 347"
                d="M5217.978,1504.771l12.7,12.145-12.7,12.145"
                transform="translate(-5213.736 -1500.529)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              />
            </svg>
          )}
        </Link>
      );
    } else if (to.startsWith("#") && !download) {
      return (
        <Link
          href={routePrefix ? routePrefix + to : to}
          className={className}
          style={style}
          onClick={onClick}
        >
          {children}
          {showArrow && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.943"
              height="32.773"
              viewBox="0 0 19.943 32.773"
              style={{ marginLeft: "1rem" }}
            >
              <path
                id="Path_347"
                data-name="Path 347"
                d="M5217.978,1504.771l12.7,12.145-12.7,12.145"
                transform="translate(-5213.736 -1500.529)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              />
            </svg>
          )}
        </Link>
      );
    } else if (!download) {
      return (
        <a
          href={to}
          target="_blank"
          rel="noreferrer"
          style={style}
          className={className}
          onClick={onClick}
        >
          {children}
          {showArrow && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.943"
              height="32.773"
              style={{ marginLeft: "1rem" }}
              viewBox="0 0 19.943 32.773"
            >
              <path
                id="Path_347"
                data-name="Path 347"
                d="M5217.978,1504.771l12.7,12.145-12.7,12.145"
                transform="translate(-5213.736 -1500.529)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              />
            </svg>
          )}
        </a>
      );
    } else if (download) {
      return (
        <a
          href={to}
          target="_blank"
          rel="noreferrer"
          download
          style={style}
          className={className}
          onClick={onClick}
        >
          {children}
          {showArrow && (
            <svg
              className={arrowClassName}
              xmlns="http://www.w3.org/2000/svg"
              width="19.943"
              height="32.773"
              style={{ marginLeft: "1rem" }}
              viewBox="0 0 19.943 32.773"
            >
              <path
                id="Path_347"
                data-name="Path 347"
                d="M5217.978,1504.771l12.7,12.145-12.7,12.145"
                transform="translate(-5213.736 -1500.529)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              />
            </svg>
          )}
        </a>
      );
    }
  } else {
    return null;
  }
};

export default InternalOrExternalLink;
