/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import Header from "~/components/Header";
import PageWrapper from "~/components/PageWrapper";
import Components from "~/components/Components";

const SubSubPage = ({ page, subPage, subSubPage, tabTitle }) => {
  const { umbData, hyphenParse, setHeaderBgImg, setCurrentRoute } = useContext(DataContext);
  useEffect(() => {
    setCurrentRoute(page);
  }, [page]);
  useEffect(() => {
    if (
      subSubPage.headerImg?.umbracoFile ||
      subPage.headerImg?.umbracoFile ||
      page.headerImg?.umbracoFile ||
      umbData.bgImg?.umbracoFile
    ) {
      setHeaderBgImg(
        subSubPage.headerImg?.umbracoFile ||
        subPage.headerImg?.umbracoFile ||
        page.headerImg?.umbracoFile ||
        umbData.bgImg?.umbracoFile
      );
    } else {
      setHeaderBgImg(umbData.bgImg?.umbracoFile);
    }
  }, [page]);
  return (
    <PageWrapper
      page={subSubPage}
      devData={subSubPage}
      title={tabTitle}
      seoTitle={
        subSubPage.seoTitle ||
        page.title + " - " + subPage.title + " - " + subSubPage.title
      }
      seoDescription={subSubPage.seoDescription}
      seoImage={subSubPage.seoImage?.url}
      bilder={subSubPage.bilder}
      customHeader={
        <Header
          page={page}
          // horizontalMenu={true}
          introText={subSubPage.intro}
          introTitle={
            <>
              <h2>{hyphenParse(subSubPage.title)}</h2>
            </>
          }
          title={page.title}
          crumbs={[
            { label: page.title, url: page.url },
            { label: subPage.title, url: subPage.url },
            {
              label: subSubPage.title,
              url: subSubPage.url,
            },
          ]}
        // bgImg={page?.bgImg?.umbracoFile || umbData.bgImg?.umbracoFile || ""}
        />
      }
    >
      {Array.isArray(subSubPage.components) &&
        subSubPage.components.length > 0 &&
        subSubPage.components.map((c) => <Components c={c} />)}
    </PageWrapper>
  );
};

export default SubSubPage;
