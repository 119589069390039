/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import qs from "qs";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import Search from "~/components/Search";
import Json from "~/components/Json";
import PageWrapper from "~/components/PageWrapper";
import M from "~/components/Message";
import NewsGrid from "~/components/NewsGrid";

// External components
import FadeIn from "react-fade-in";
import { FcSearch, FcDocument } from "react-icons/fc";
import AnimH from "~/components/AnimH";

const SearchResults = () => {
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const {
    doSearch,
    searchMessage,
    parse,
    searchResults,
    devStatus,
    routes,
    setSearchResults,
    searchQuery,
    setSearchQuery,
    setSearchMessage,
  } = useContext(DataContext);
  useEffect(() => {
    let searchBox = document.getElementById("results-search-box");
    if (searchBox) {
      searchBox.focus();
    }
  }, []);

  // URL query
  let query = useQuery();
  const urlQuery = query.get("q");

  useEffect(() => {
    if (urlQuery) {
      setSearchQuery(urlQuery);
      doSearch(urlQuery);
    } else {
      setSearchResults(null);
    }
  }, [urlQuery]);

  let navigate = useNavigate();
  useEffect(() => {
    if (searchQuery) {
      navigate({
        pathname: "/sok",
        search: `?q=${qs.stringify(qs.parse(searchQuery)).slice(0, -1)}`,
      });
    }
  }, [searchQuery]);

  return (
    <PageWrapper title="Søk">
      <div className="container  pt-4">
        <div className="justify-content-center row align-items-center">
          <div className="col-12 col-md-6 col-xxl-4 px-0">
            <Search id="results-search-box" width="400px" className="w-100" />
          </div>{" "}
          <div className="col-auto d-flex gap-2 justify-content-start">
            <div
              className="btn btn-primary mt-3 mt-md-0"
              onClick={() => {
                doSearch(searchQuery);
              }}
            >
              Søk nå
            </div>
          </div>
        </div>
        <hr />
        {/* {urlQuery !== null && (
          <div className="col-12 col-md-6 px-0">
            <h2 className="h3 mb-md-0">
              <span style={{ fontWeight: "normal" }}>
                Viser resultater for:
              </span>
              <span> "{urlQuery}"</span>
            </h2>
          </div>
        )} */}
        <AnimH trigger={searchQuery?.length > 0}>
          <div className="text-center w-100 d-flex align-items-center justify-content-center">
            <span>
              Viser treff på "<em>{searchQuery}</em>"
            </span>
            <div
              className="ms-3 btn btn-outline-danger db__search-message__btn"
              onClick={() => {
                navigate({
                  pathname: "/sok",
                });
                setSearchResults(null);
                setSearchMessage("");
                setSearchQuery("");
                document.querySelector("#results-search-box").value = "";
                document.querySelector("#results-search-box").focus();
                if (urlQuery) {
                  window.location.href = window.location.pathname;
                }
              }}
            >
              Fjern søk
            </div>
          </div>
        </AnimH>
        {searchResults !== null && searchResults?.length > 0 && (
          <div className=" py-4 search-results">
            <h4>Resultater</h4>
            <NewsGrid
              items={searchResults?.map((res) => {
                return {
                  ...res,
                  url: res.url,
                };
              })}
              showUrl={true}
              icon={<FcDocument size="2rem" />}
            />
          </div>
        )}
        {searchResults !== null && devStatus && (
          <FadeIn>
            <Json
              obj={searchResults?.map((res) => {
                return {
                  ...res,
                  url: res.url,
                  img: routes?.filter((r) => r.title === res.title)[0]?.seoImage
                    ?.url,
                };
              })}
            />
          </FadeIn>
        )}
        {searchMessage && !searchResults?.length > 0 && (
          <div className="d-flex justify-content-center mt-4">
            <M trigger={searchMessage}>
              <FcSearch size="2rem" className="me-3" />
              {parse(searchMessage)}
            </M>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default SearchResults;
