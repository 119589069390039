/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

// External components
import { Helmet } from "react-helmet";

// Contexts
import { DataContext } from "~/contexts/Data";

const Meta = ({ description, image, title, seoTitle }) => {
  const { setSeoDevData } = useContext(DataContext);
  const { pathname } = useLocation();
  useEffect(() => {
    let object = {};
    document
      .querySelectorAll("meta")
      .forEach(
        (el) =>
        (object[
          el.hasAttribute("property") ? el.getAttribute("property") : el.name
        ] = el.content)
      );
    setSeoDevData(object);
  }, [pathname]);
  return (
    <Helmet>
      <title>{`${title ? `Drottningborg - ${title}` : "Drottningborg VGS"
        }`}</title>
      {title && <meta name="title" content={title} />}
      {seoTitle && <meta property="og:title" content={seoTitle} />}
      {seoTitle && <meta property="twitter:title" content={seoTitle} />}

      <meta property="og:type" content="website" />

      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {description && (
        <meta property="twitter:description" content={description} />
      )}

      {image && (
        <meta property="og:image" content={image + "&width=1200&height=627"} />
      )}
      {image && <meta property="twitter:image" content={image}></meta>}
      {image && <meta property="twitter:card" content="summary_large_image" />}
    </Helmet>
  );
};

export default Meta;
