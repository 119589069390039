/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import Header from "~/components/Header";
import PageWrapper from "~/components/PageWrapper";
import NewsGrid from "~/components/NewsGrid";
import Section from "~/components/Section";

const Page = ({ page, tabTitle }) => {
  const { umbData, setHeaderBgImg, setCurrentRoute } = useContext(DataContext);
  useEffect(() => {
    setCurrentRoute(page);
  }, [page]);

  useEffect(() => {
    if (page.headerImg?.umbracoFile || page.bgImg?.umbracoFile) {
      setHeaderBgImg(page.headerImg?.umbracoFile || page.bgImg?.umbracoFile);
    } else {
      setHeaderBgImg(umbData.bgImg?.umbracoFile);
    }
  }, [page]);
  return (
    <PageWrapper
      devData={page}
      page={page}
      components={page.components}
      title={tabTitle}
      introText={page.introText}
      seoTitle={page.seoTitle}
      seoDescription={page.seoDescription}
      seoImage={page.seoImage?.url}
      customHeader={
        <>
          <Header
            page={page}
            // horizontalMenu={true}
            introText={page.intro}
            // introTitle={<>{page.title && <h2>{hyphenParse(page.title)}</h2>}</>}
            title={page.title}
            crumbs={[{ label: page.title, url: page.url }]}
            bgImg={
              page.headerImg?.umbracoFile ||
              page?.bgImg?.umbracoFile ||
              umbData.bgImg?.umbracoFile ||
              ""
            }
          />
        </>
      }
    >
      {/* {page.children.map((child, index) => (
                  <div id={child.id}>
                    <HighlightSection
                      title={child.title}
                      // title,
                      // text,
                      variant={`${index % 2 ? "left" : "right"}`}
                      btnUrl={child.url}
                      btnLabel="Les mer"
                      img={{
                        url: "https://images.unsplash.com/photo-1634767704190-74f517c9ab1d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=774&q=80",
                      }}
                      // bgColor,
                      // variant = "left",
                      // containImage = "cover",
                      // shadow = false,
                    />
                  </div>
                ))} */}

      {page?.children?.length > 0 && (
        <Section
          title="subPages"
          className="container-xxl px-0 px-sm-5 px-xxl-0 mt-4"
        >
          <h3 className="px-4 px-md-0">Undersider</h3>
          <NewsGrid
            items={page.children}
            limit={page.children.length}
            hideText
          // minHeight={minHeight}
          />
        </Section>
      )}

      {/* {Array.isArray(page.components) &&
                    page.components.length > 0 &&
                    page.components.map((c) => <Component c={c} />)} */}
    </PageWrapper>
  );
};

export default Page;
