import React from "react";

// Internal components
import TextOnImage from "~/components/TextOnImage";
import HighlightSection from "~/components/HighlightSection";
import Section from "~/components/Section";
import FeaturedGrid from "~/components/FeaturedGrid";
import VideoSection from "~/components/VideoSection";
import Faq from "~/components/Faq";
import BuildingPicker from "~/components/BuildingPicker";

const Components = ({ items, page }) => {
  const getChildren = (parent) => {
    let array = [];
    parent.nodes.map((n) => n.children.map((ch) => array.push(ch)));
    return array;
  };
  return (
    <>
      {items.map(
        (item, index) =>
          !item.umbracoNaviHide && (
            <>
              {item.type.toLowerCase() === "collection" && (
                <Section
                  title={`featuredGrid - ${item.variant}`}
                  devData={item}
                  key={item.id}
                  className="container-xxl px-0  px-sm-5 px-xxl-0"
                >
                  <BuildingPicker allBuildings={item} page={page} />
                </Section>
              )}
              {item.type.toLowerCase() === "featuredgrid" && (
                <Section
                  title={`featuredGrid - ${item.variant}`}
                  devData={item}
                  key={item.id}
                  className="container-xxl px-0  px-sm-5 px-xxl-0"
                >
                  {Array.isArray(item.nodes) && item.nodes.length > 0 ? (
                    <>
                      {item.showChildren ? (
                        <FeaturedGrid
                          // items={n.children}
                          items={
                            Array.isArray(item.fremhevedeElementer)
                              ? item.nodes[0]?.children[0]?.type?.toLowerCase() ===
                                "nyhet"
                                ? [
                                  ...getChildren(item)
                                    .reverse()
                                    .splice(
                                      0,
                                      item.limit === "0" ? 4 : item.limit
                                    ),
                                  ...item.fremhevedeElementer,
                                ]
                                : [
                                  ...getChildren(item).splice(
                                    0,
                                    item.limit === "0" ? 4 : item.limit
                                  ),
                                  ...item.fremhevedeElementer,
                                ]
                              : item.nodes[0]?.children[0]?.type?.toLowerCase() ===
                                "nyhet"
                                ? [
                                  ...getChildren(item)
                                    .reverse()
                                    .splice(
                                      0,
                                      item.limit === "0" ? 4 : item.limit
                                    ),
                                  item.fremhevedeElementer,
                                ]
                                : [
                                  ...getChildren(item).splice(
                                    0,
                                    item.limit === "0" ? 4 : item.limit
                                  ),
                                  item.fremhevedeElementer,
                                ]
                          }
                          variant={item.variant}
                          limit={item.limit === "0" ? undefined : item.limit}
                          minHeight={250}
                        />
                      ) : (
                        <FeaturedGrid
                          items={
                            Array.isArray(item.fremhevedeElementer)
                              ? [...item.nodes, ...item.fremhevedeElementer]
                              : [...item.nodes, item.fremhevedeElementer]
                          }
                          variant={item.variant}
                          // limit={item.limit === "0" ? undefined : item.limit}
                          minHeight={250}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {item.fremhevedeElementer && (
                        <FeaturedGrid
                          items={
                            Array.isArray(item.fremhevedeElementer)
                              ? [...item.fremhevedeElementer]
                              : [item.fremhevedeElementer]
                          }
                          variant={item.variant}
                          limit={item.limit === "0" ? undefined : item.limit}
                          minHeight={250}
                        />
                      )}
                    </>
                  )}
                </Section>
              )}
              {item.type.toLowerCase() === "textonimage" && (
                <Section
                  key={item.id}
                  title="textOnImage"
                  devData={item}
                  className="container-xxl px-0  px-sm-5 px-xxl-0"
                >
                  <TextOnImage
                    src={
                      item.bgImg?.umbracoFile +
                      "?width=2000&quality=70&sharpen=10&sigma=1.5&threshold=10"
                    }
                    placeholder={
                      item.bgImg?.umbracoFile + "?width=200&quality=20"
                    }
                    text={item.text}
                  />
                </Section>
              )}
              {item.type.toLowerCase() === "highlightsection" && (
                <Section
                  key={item.id}
                  title="highlightSection"
                  devData={item}
                  className="container-xxl px-0  px-sm-5 px-xxl-0"
                >
                  <HighlightSection
                    title={item.title}
                    text={item.text}
                    bgColor={item.bgColor}
                    containImage={item.containImage}
                    btnLabel={item.btnLabel}
                    btnUrl={item.btnUrl?.url}
                    img={item.bgImg?.umbracoFile}
                  />
                </Section>
              )}
              {item.type.toLowerCase() === "video" &&
                (item.videoSrc?.url || item.videoUrl?.url) && (
                  <Section
                    key={item.id}
                    title="video"
                    devData={item}
                    className="container-xxl px-0 px-sm-5 px-xxl-0"
                  >
                    <VideoSection
                      url={item.videoSrc?.umbracoFile || item.videoUrl?.url}
                      videoTitle={item.videoSrc?.title || item.videoUrl?.label}
                      text={item.videoText}
                    />
                  </Section>
                )}
              {item.type.toLowerCase() === "faq" && (
                <Section
                  key={item.id}
                  title="faq"
                  devData={item}
                  className="container-xxl px-0 px-sm-5 px-xxl-0"
                >
                  {/* {index === 0 && (
                    <HelpSection
                      faq={true}
                      className={`d-none d-xl-flex mb-5`}
                    />
                  )} */}
                  <Faq
                    items={
                      Array.isArray(item.faqList)
                        ? item.faqList
                        : [item.faqList]
                    }
                    bgImg={item.faqImg?.umbracoFile}
                    title={item.title}
                  />
                </Section>
              )}
              {item.faqGroups && (
                <Section
                  key={item.id}
                  title="faq"
                  devData={item}
                  className="container-xxl px-0 px-sm-5 px-xxl-0"
                >
                  <div className="row faq-groups">
                    {item.faqGroups.map((group) => (
                      <div className="col-lg-6">
                        <Faq
                          isGroup={true}
                          items={
                            Array.isArray(group.faqList)
                              ? group.faqList
                              : [group.faqList]
                          }
                          bgImg={group.faqImg?.umbracoFile}
                          title={group.title}
                        />
                      </div>
                    ))}
                  </div>
                </Section>
              )}
            </>
          )
      )}
    </>
  );
};

export default Components;
