import React, { useContext, useRef } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// External components
import { FaSearch } from "react-icons/fa";

const Search = ({ placeholder, id, width, className, onChange }) => {
  let searchBox = useRef(null);
  const { searchQuery, setSearchQuery, doSearch } = useContext(DataContext);
  return (
    <>
      <label
        className={`btn btn--outline-subtle-grey label ${className} search-no-x`}
        style={{ paddingLeft: "1rem", width: width }}
      >
        <input
          id={id}
          placeholder={placeholder || "Søk"}
          type="search"
          ref={searchBox}
          // onChange={(e) => {
          //   setSearchQuery(e.target.value);
          // }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setSearchQuery(e.target.value);
              doSearch(e.target.value);
            }
          }}
          style={{
            cursor: "text",
            width: searchQuery.length > 0 ? "100%" : "80%",
          }}
        />
        <FaSearch size="1.5rem" className="btn__icon" />
      </label>
    </>
  );
};

export default Search;
