import React from "react";

// External components
import { SRLWrapper } from "simple-react-lightbox";

// Internal components
import LazyBlur from "~/components/LazyBlur";

const UmbracoFileGallery = ({ items }) => {
  const checkExtension = (ext) => {
    if (["png", "jpeg", "jpg", "png", "svg"].indexOf(ext) >= 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <SRLWrapper
      options={{
        disableWheelControls: true,
      }}
    >
      {Array.isArray(items) && items.length > 0 && (
        <div className="umbraco-file-gallery row">
          {items.map(
            (bilde) =>
              bilde.umbracoFile &&
              checkExtension(bilde.umbracoExtension) && (
                <div className="col-6 col-lg-4 col-xxl-3 px-0">
                  <a
                    href={bilde.umbracoFile + "?width=1200"}
                    className="umbraco-file-gallery__item"
                  >
                    <LazyBlur
                      src={bilde.umbracoFile + "?width=400"}
                      className="umbraco-file-gallery__item__image"
                    />
                  </a>
                </div>
              )
          )}
        </div>
      )}
    </SRLWrapper>
  );
};

export default UmbracoFileGallery;
