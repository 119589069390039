import React, { useState, useRef, useEffect } from "react";

// External components
import FadeIn from "react-fade-in";
import { FaSearch } from "react-icons/fa";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FcSearch, FcDocument } from "react-icons/fc";

// Internal components
import M from "~/components/Message";
import NewsGrid from "~/components/NewsGrid";

const NewsGridSearchable = ({ items, limit = 4 }) => {
  let articleSearchBox = useRef(null);
  const [articleSearchQuery, setArticleSearchQuery] = useState("");
  const [amountLimit, setAmountLimit] = useState(limit);
  const [showArticles, setShowArticles] = useState(true);
  const [visible, setVisible] = useState(items);
  useEffect(() => {
    console.log(items);
    if (articleSearchQuery.length > 0 && Array.isArray(items)) {
      let filtered = items.filter((item) =>
        JSON.stringify(item)
          .toLowerCase()
          .includes(articleSearchQuery.toLowerCase())
          ? true
          : false
      );
      if (filtered.length > 0) {
        setVisible(filtered);
      } else {
        setVisible([]);
      }
    } else {
      setVisible(items);
    }
  }, [articleSearchQuery, items]);
  return (
    <div className="w-100 news-grid-searchable">
      <div className="news-grid-searchable__controls">
        <label
          className={`btn btn--outline-subtle-grey label news-grid-searchable__controls__search mb-4 mb-md-0`}
          style={{ paddingLeft: "1rem" }}
        >
          <input
            placeholder={"Søk"}
            type="search"
            // id={items[0].id + "search"}
            ref={articleSearchBox}
            onChange={(e) => {
              setTimeout(() => {
                setArticleSearchQuery(e.target.value);
              }, 200);
            }}
            style={{
              cursor: "text",
              width: articleSearchQuery.length > 0 ? "100%" : "80%",
            }}
          />
          {!articleSearchBox.current?.value && (
            <FadeIn transitionDuration="150">
              <FaSearch size="1.5rem" className="btn__icon" />
            </FadeIn>
          )}
        </label>
        <div className="news-grid-searchable__controls__buttons">
          {!showArticles && (
            <div
              className="btn btn--primary-light"
              onClick={() => setShowArticles(true)}
            >
              <BsChevronDown size="2rem" />
            </div>
          )}
          {showArticles && (
            <div
              className="btn btn--light-red"
              onClick={() => setShowArticles(false)}
            >
              <BsChevronUp size="2rem" />
            </div>
          )}
        </div>
      </div>
      {showArticles && (
        <>
          <div className="news-grid-searchable__grid py-4">
            <NewsGrid items={visible} icon={<FcDocument size="2rem" />} />
          </div>
          <div className="text-center w-100 mb-4">
            <M trigger={visible.length === 0 && articleSearchQuery.length > 0}>
              <FcSearch size="2rem" className="me-3" />
              <strong>Ingen resultater på {articleSearchQuery}</strong>
            </M>
          </div>
          <div>
            <div className="d-flex justify-content-center">
              {(amountLimit !== items.length || visible.length === 0) && (
                <div
                  className="btn btn--primary-light me-3"
                  onClick={() => {
                    setAmountLimit(items.length);
                    document.getElementById(items[0].id + "search").value = "";
                    setArticleSearchQuery("");
                  }}
                >
                  Last inn alle
                </div>
              )}
              {amountLimit !== limit && visible.length !== 0 && (
                <div
                  className="btn btn--light-red"
                  onClick={() => setAmountLimit(limit)}
                >
                  Skjul
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewsGridSearchable;
