import React, { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollRestoration = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    if (location) {
      if (location.hash) {
        let el = document.getElementById(location?.hash.replace("#", ""));

        el?.scrollIntoView({
          behavior: "instant",
          block: "start",
          inline: "start",
        });
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
      }
    }
  }, [location]);
  return <>{children}</>;
};

export default ScrollRestoration;
