/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

// Internal components
import Meta from "~/components/Meta";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import Json from "~/components/Json";
import Components from "~/components/Components";
import AnimateMount from "~/components/AnimateMount";
import Bilder from "~/components/Bilder";

// Internal components
import Section from "~/components/Section";
import UmbracoFileGallery from "~/components/UmbracoFileGallery";
import Files from "~/components/Files";
import Ansatte from "~/components/Ansatte";
import Ansatt from "~/components/Ansatt";
import NewsGridSearchable from "~/components/NewsGridSearchable";
import HelpSection from "~/components/HelpSection";

// Contexts
import { DataContext } from "~/contexts/Data";
import ScrollRestoration from "./ScrollRestoration";

const PageWrapper = ({
  children,
  page,
  customHeader,
  title,
  introText,
  devData,
  seoTitle,
  seoDescription,
  seoImage,
  bilder,
}) => {
  const { setCurrentPageDevData, umbData, devStatus, hyphenParse } =
    useContext(DataContext);
  useEffect(() => {
    setCurrentPageDevData(devData);
  }, [devData]);
  return (
    <div className="page-wrapper">
      <ScrollRestoration />
      <Meta
        // title={pathname.replace(/-/g, " ").replace(/\//g, " - ")}
        title={title}
        seoTitle={seoTitle}
        description={seoDescription || introText}
        image={seoImage}
      />
      <div>
        {customHeader || (
          <Header
            page={page}
            introText={introText || ""}
            title={title || ""}
            bgImg={
              page?.headerImg?.umbracoFile ||
              page?.bgImg?.umbracoFile ||
              umbData.bgImg?.umbracoFile ||
              ""
            }
          />
        )}
        <section className="page-wrapper__content white-bg-img">
          <main className="page-wrapper__content-flex">
            {/* {devData && devStatus && <Json obj={devData} />} */}
            <AnimateMount trigger={devStatus && (page || devData)}>
              <div className="container-xxl px-4 px-xxl-0 pt-5">
                <h3>Current page</h3>
                <Json obj={page || devData} />
              </div>
            </AnimateMount>
            {/* <Json obj={components} /> */}
            {/* {Array.isArray(components) && components.length > 0 && (
              <Json obj={components} />
            )} */}

            {/* COMPONENTS */}
            {page && page.components && (
              <>
                {Array.isArray(page.components) &&
                  page.components.length > 0 &&
                  page.components[0]?.type ? (
                  <Components items={page.components} page={page} />
                ) : (
                  ""
                )}

                {typeof page.components === "object" && page.components.type ? (
                  <Components items={[page.components]} page={page} />
                ) : (
                  ""
                )}
              </>
            )}
            {page && page.type && (
              <>
                <div className="container-xxl px-4 px-xxl-0">
                  {/* ANSATTE */}
                  {Array.isArray(page?.children) &&
                    page?.children?.length > 0 &&
                    page?.children[0]?.type &&
                    page?.children[0]?.type.toLowerCase() === "ansatt" ? (
                    <Ansatte
                      people={page.children?.map((ansatt) => {
                        return {
                          ...ansatt,
                          navn: `${ansatt.fornavn} ${ansatt.etternavn}`,
                        };
                      })}
                    />
                  ) : (
                    ""
                  )}
                  {/* ANSATT */}
                  {page.type && page.type.toLowerCase() === "ansatt" && (
                    <Ansatt content={page} />
                  )}
                  {(introText || title) && page.bodyText && (
                    <hr className="mb-5" style={{ height: "1px" }} />
                  )}
                  <div className="row">
                    {page?.bodyText && (
                      <Section
                        title="bodyText"
                        devData={page.bodyText}
                        className="col-xl-7 p-0 mb-5 pe-md-4"
                      >
                        <div className="richtext">
                          {hyphenParse(page.bodyText)}
                        </div>
                      </Section>
                    )}
                    <Bilder images={bilder} />
                  </div>
                  {(page?.bildemappe?.children?.length > 0 ||
                    page?.bildegalleri?.length > 0) && (
                      <div className="mb-5">
                        {page?.bildemappe &&
                          Array.isArray(page.bildemappe?.children) && (
                            <Section title="bildemappe" devData={page.bildemappe}>
                              <UmbracoFileGallery
                                items={page.bildemappe.children}
                              />
                            </Section>
                          )}
                        {page?.bildegalleri &&
                          Array.isArray(page.bildegalleri) &&
                          page.bildegalleri.length > 0 && (
                            <Section
                              title="bildegalleri"
                              devData={page.bildegalleri}
                            >
                              <UmbracoFileGallery items={page.bildegalleri} />
                            </Section>
                          )}
                      </div>
                    )}
                </div>
                {/* FILMAPPE */}
                {page?.filmappe && page.filmappe?.children?.length > 0 ? (
                  <Section
                    title="filmappe"
                    devData={page.filmappe}
                    className="col-12 px-0 container-xxl"
                  >
                    <Files items={page.filmappe?.children} c={page.filmappe} />
                  </Section>
                ) : (
                  ""
                )}
                {/* {page.title && <h2>{page.title}</h2>} */}
                {/* {Array.isArray(page?.children) && page?.children?.length > 0 && (
                  <Section
                    title={page?.children[0].type}
                    devData={page.filmappe}
                    className="col-12 p-0"
                  >
                    <Json obj={page?.children} />
                    {page?.children.map(
                      (subSub) =>
                        !subSub.umbracoNaviHide && (
                          <>
                            {subSub.type.toLowerCase() === "nyhet " && (
                              <li>
                                <Link to={subSub.url}>
                                  {subSub.title} - {subSub.url} - {subSub.type}
                                </Link>
                              </li>
                            )}
                          </>
                        )
                    )}
                  </Section>
                )} */}
                {/* <h3>{page?.children[0]?.type}</h3> */}

                {/* NYHETER */}
                {page?.children[0]?.type &&
                  page?.children[0]?.type === "nyhet" && (
                    <div className="container-xxl px-4 px-xxl-0">
                      <NewsGridSearchable
                        items={[...page.children].reverse()}
                      />
                    </div>
                  )}

                {/* {page.children.map((child, index) => (
                  <div id={page.id}>
                    <HighlightSection
                      title={page.title}
                      // title,
                      // text,
                      // variant={`${index % 2 ? "left" : "right"}`}
                      btnUrl={page.url}
                      btnLabel="Les mer"
                      // img={{
                      //   url: "https://images.unsplash.com/photo-1634767704190-74f517c9ab1d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=774&q=80",
                      // }}
                      // bgColor,
                      // variant = "left",
                      // containImage = "cover",
                      // shadow = false,
                    />
                  </div>
                ))} */}
              </>
            )}
            {children}
            {page?.showHelpSection && (
              <Section
                title="helpSection"
                className="container-xxl px-4 px-xxl-0"
              >
                <HelpSection />
              </Section>
            )}
          </main>
        </section>
      </div>
      <div className="pt-5 white-bg-img">
        <Footer />
      </div>
    </div>
  );
};

export default PageWrapper;
