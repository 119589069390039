import React, { useRef } from "react";

// Transition
import { motion, AnimatePresence } from "framer-motion";
import { useMeasure } from "~/components/use-measure";

const Animate = ({
  trigger,
  children,
  id,
  delay,
  duration,
  initial,
  animate,
  exit,
  animationKey,
}) => {
  const ref = useRef(null);
  function getAutoHeightDuration(height) {
    if (!height) return 0;
    const constant = height / 36;
    return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
  }
  const bounds = useMeasure(ref);
  return (
    <>
      <AnimatePresence  >
        {trigger &&
          <motion.div
            key={animationKey}
            initial={initial || { height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            style={{ overflow: "hidden" }}
            exit={exit || initial || { height: 0, opacity: 0 }}
            transition={{
              ease: [0.075, 0.82, 0.165, 1],
              duration:
                typeof duration === "number"
                  ? duration
                  : getAutoHeightDuration(bounds.height) / 500,
            }}
          >
            <div ref={ref}>{children}</div>
          </motion.div>
        }
      </AnimatePresence>
    </>
  );
};

export default Animate;
