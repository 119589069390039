import React from "react";

// Internal components
import PageWrapper from "~/components/PageWrapper";

// Assets
import errImg from "/404.png";

const NotFound = () => {
  return (
    <PageWrapper>
      <div
        className="d-flex align-items-center text-center justify-content-center flex-column"
        style={{ minHeight: "50vh" }}
      >
        <img loading="lazy" src={errImg} width="400" alt="not found 404" />
        <h1>404</h1>
        <p>Her har du funnet en side som ikke eksisterer</p>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
