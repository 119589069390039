import React from "react";

// External components
import AnimateHeight from "react-animate-height";

const AnimH = ({ trigger, children }) => (
  <AnimateHeight
    duration={150}
    easing="cubic-bezier(0.16, 1, 0.3, 1)"
    height={trigger ? "auto" : 0}
  >
    {children}
  </AnimateHeight>
);

export default AnimH;
