import React, { useState, useContext } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

const ScrollTop = () => {
  const { scrolled, scrollDirection } = useContext(DataContext);
  const [showText, setShowText] = useState(false);
  return (
    <div
      className={`scrolltop ${showText ? "scrolltop--visible-text" : ""} ${scrolled > 100 && scrollDirection === "up" ? "show" : "hide"
        }`}
    >
      <p className={`scrolltop_text`}>Tilbake til toppen</p>
      <svg
        onMouseEnter={() => setShowText(true)}
        onMouseLeave={() => setShowText(false)}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "auto" });
          setShowText(false);
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="29.922"
        height="29.922"
        viewBox="0 0 29.922 29.922"
      >
        <g
          id="Icon_feather-arrow-up-right"
          data-name="Icon feather-arrow-up-right"
          transform="translate(3 14.961) rotate(-45)"
        >
          <path
            id="Path_128"
            data-name="Path 128"
            d="M10.5,27.416,27.416,10.5"
            transform="translate(-10.5 -10.5)"
            fill="none"
            stroke="#145468"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_129"
            data-name="Path 129"
            d="M10.5,10.5H27.416V27.416"
            transform="translate(-10.5 -10.5)"
            fill="none"
            stroke="#145468"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </div>
  );
};

export default ScrollTop;
