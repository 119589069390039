import React, { useContext } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// External components
import { BsFacebook, BsInstagram, BsPlus, BsYoutube } from "react-icons/bs";
import { BiMobileVibration } from "react-icons/bi";
import { HiHeart } from "react-icons/hi";

// Internal components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import Search from "~/components/Search";
import Logo from "~/components/Logo";
import Visma from "~/components/Visma";

const Social = ({ umbData, size, className, childClass }) => (
  <div className={` ${className}`}>
    {umbData.facebook?.url && (
      <InternalOrExternalLink
        to={umbData.facebook?.url}
        className={` ${childClass} btn btn--transparent`}
      >
        <BsFacebook size={size} color="#3b5998" />
      </InternalOrExternalLink>
    )}
    {umbData.youtube?.url && (
      <InternalOrExternalLink
        to={umbData.youtube?.url}
        className={` ${childClass}  btn btn--transparent`}
      >
        <BsYoutube size={size} color="#c4302b" />
      </InternalOrExternalLink>
    )}
    {umbData.instagram?.url && (
      <InternalOrExternalLink
        to={umbData.instagram?.url}
        className={`  ${childClass} btn btn--transparent`}
      >
        <BsInstagram size={size} color="#833AB4" />
      </InternalOrExternalLink>
    )}
    {umbData.visma?.url && (
      <InternalOrExternalLink
        to={umbData.visma?.url}
        className={` ${childClass}  btn btn--transparent `}
      >
        <Visma size={size} />
      </InternalOrExternalLink>
    )}
  </div>
);

const KontaktBtn = ({ url }) => (
  <InternalOrExternalLink to={url} className="btn btn--grey btn--start">
    <BiMobileVibration
      size="1.5rem"
      className="btn__icon btn__icon--lg btn__icon--left"
    />
    Kontakt oss
  </InternalOrExternalLink>
);
const GiverBtn = ({ url }) => (
  <InternalOrExternalLink to={url} className="btn btn--light-red btn--start">
    <HiHeart
      size="1.5rem"
      className="btn__icon btn__icon--lg btn__icon--left"
    />
    Givertjeneste
  </InternalOrExternalLink>
);

const Footer = () => {
  const { umbData, hyphenParse } = useContext(DataContext);
  return (
    <footer className="footer px-4 px-sm-5">
      <div className="container">
        <div className="footer__grid">
          <div>
            <Logo footer={true} />
            <div className="mt-5">
              <Social
                umbData={umbData}
                className="social"
                childClass="social__item"
              />
            </div>
          </div>
          {umbData?.adresse && <div>{hyphenParse(umbData.adresse)}</div>}
          <div className="footer__buttons">
            {umbData.sokNa?.url && (
              <InternalOrExternalLink
                to={umbData.sokNa.url}
                className="btn btn--blue-gradient"
              >
                {umbData.sokNa.label}
                <BsPlus size="3rem" className="btn__icon btn__icon--lg ms-2" />
              </InternalOrExternalLink>
            )}

            {umbData.contact?.url && <KontaktBtn url={umbData.contact?.url} />}
            {umbData.give?.url && <GiverBtn url={umbData.give?.url} />}
            <InternalOrExternalLink
              to="/sok"
              onClick={(e) => {
                if (window.location.pathname === "/sok") {
                  e.preventDefault();
                  document.getElementById("results-search-box")?.focus();
                }
              }}
              className="no-input"
            >
              <Search placeholder="Søk på siden" />
            </InternalOrExternalLink>
          </div>
        </div>
      </div>
      <svg
        className="footer__bg"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 3665.254 579"
      >
        <path
          d="M2253.281,145.107h-1920L1542.026,696.813c57.2,26.106,140.987,34.3,215.318,21.041L3998.535,145.107Z"
          transform="translate(3998.535 724.107) rotate(180)"
          fill="#f7f7f7"
        />
      </svg>
    </footer>
  );
};

export default Footer;
