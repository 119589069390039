import React from "react";

// External components
import ReactPlayer from "react-player";
const BgVideo = ({ c, style, className }) => {
  console.log(c.url);
  return (
    <div className={`bg-video ${className}`} style={style}>
      {c.url && (
        <ReactPlayer
          url={c.url}
          controls={false}
          playsinline
          muted
          playing
          className="video-background"
          loop
          config={{
            youtube: {
              playerVars: {
                showinfo: 0,
                disablekb: 1,
                fs: 0,
                playsinline: 1,
                rel: 0,
                autohide: 1,
                modestbranding: 1,
                autoplay: 1,
              },
            },
            vimeo: {
              background: true,
              byline: false,
              keyboard: false,
              muted: true,
              playsinline: true,
              portrait: true,
            },
          }}
        />
      )}
    </div>
  );
};

export default BgVideo;
