import React from "react";

// External components
import parse from "html-react-parser";

// Internal components
import LazyBlur from "~/components/LazyBlur";

const TextOnImage = ({ src, placeholder, icon, text }) => {
  return (
    <div className="text-on-image">
      {src && (
        <LazyBlur
          className="text-on-image__bg"
          src={src}
          placeholder={placeholder}
        />
      )}
      <div className="text-on-image__overlay" />
      {icon && (
        <img
          loading="lazy"
          src={icon}
          alt="icon"
          className="text-on-image__icon"
        />
      )}
      {text && parse(text)}
    </div>
  );
};

export default TextOnImage;
