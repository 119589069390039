import React, { useState } from "react";

// External components
import AnimateHeight from "~/components/AnimateHeight";

const Collapse = ({ header, children, className, style, openClass, open }) => {
  const [visible, setVisible] = useState(open);
  const variants = {
    open: {
      opacity: 1,
      height: "auto",
    },
    collapsed: { opacity: 0, height: 0, overflow: "hidden" },
  };
  return (
    <div className={`${className} ${visible ? openClass : ""}`} style={style}>
      <div onClick={() => setVisible(!visible)}>{header}</div>
      <AnimateHeight variants={variants} isVisible={visible}>
        {children}
      </AnimateHeight>
    </div>
  );
};

export default Collapse;
