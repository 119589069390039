import React, { useContext, useState, useEffect } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// External components
import { IoIosWarning, IoMdClose } from "react-icons/io";

const Banner = () => {
  const { umbData, parse } = useContext(DataContext);
  const [open, setOpen] = useState(umbData?.showDangerBanner);

  useEffect(() => {
    if (umbData?.showDangerBanner) {
      setOpen(true);
    }
  }, [umbData?.showDangerBanner]);
  return (
    <>
      {open && (
        <div
          className={`banner ${umbData.dangerCode === "grønn" ? "banner--green" : ""
            } ${umbData.dangerCode === "gul" ? "banner--yellow" : ""}`}
        >
          <div className="banner__icon">
            <IoIosWarning />
          </div>
          <div>
            {umbData.dangerIntro && (
              <span className="banner__intro">
                {parse(umbData.dangerIntro)}
              </span>
            )}
            {umbData.dangerText && (
              <span className="banner__text">{parse(umbData.dangerText)}</span>
            )}
          </div>
          <div className="banner__close" onClick={() => setOpen(false)}>
            <IoMdClose />
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
