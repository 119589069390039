import React, { useRef, useEffect, useCallback, useState } from "react";

// External components
// import { FcSearch } from 'react-icons/fc';
// import { MdOutlineClose } from 'react-icons/md';
import { DebounceInput } from "react-debounce-input";
import Fuse from "fuse.js";
import { FaSearch } from "react-icons/fa";
import FadeIn from "react-fade-in";

const Suggestions = ({ q, items, returnSelected, limitToKeys }) => {
  const [suggItems, setSuggItems] = useState(null);
  useEffect(() => {
    if (q?.length > 0) {
      let suggValues = {};
      [...items]
        .filter((item) => JSON.stringify(item).toLowerCase().includes(q))
        .forEach((item) => {
          Object.entries(item).forEach((keyVal) => {
            let val = keyVal[1];
            if (
              typeof val === "string" &&
              JSON.stringify(val).toLowerCase().includes(q)
            ) {
              if (limitToKeys.includes(keyVal[0])) {
                let key = limitToKeys.filter((k) => k === keyVal[0]);
                if (suggValues[key]) {
                } else {
                  suggValues[key] = [];
                }
                suggValues[key].push(keyVal[1]);
              }
            }
          });
        });
      let keys = Object.keys(suggValues);
      let values = Object.values(suggValues);
      let obj = {};
      keys.forEach((key, index) => {
        obj[key] = [...new Set(values[index])];
      });
      setSuggItems(obj);
    } else {
      setSuggItems(null);
    }
  }, [q, items, limitToKeys]);
  const Highlight = ({ string, query }) => {
    if (string && query) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: string
              .toLowerCase()
              .replace(
                query,
                `<span class="db__search-suggestion-highlight">${query}</span>`
              ),
          }}
        ></div>
      );
    } else {
      return "";
    }
  };
  return (
    <>
      {suggItems && (
        <div className="db__search-suggestions">
          {Object.entries(suggItems)?.map((keyVal) => (
            <div>
              <p className="text-muted hr-title mb-0">
                <small>{keyVal[0]}</small>
              </p>

              {keyVal[1]?.map((n) => (
                <div
                  className="db__search-suggestion hover"
                  onMouseDown={() => {
                    setSuggItems(null);
                    returnSelected(n);
                  }}
                >
                  <Highlight string={n} query={q} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const SearchBox = ({
  ariaLabel = "Søk",
  placeholder = "søk",
  inputClassName,
  items,
  limitToKeys = ["title"],
  minLength = 0,
  returnResults,
  returnSearchMessage,
}) => {
  // Import from context
  const [searchQuery, setSearchQuery] = useState(undefined);
  const [suggestionQuery, setSuggestionQuery] = useState(undefined);

  // Set ref
  const searchBox = useRef();

  const updateResults = useCallback(
    (val) => {
      returnResults(val);
    },
    [returnResults]
  );

  const [searchHits, setSearchHits] = useState(0);
  const doSearch = useCallback(
    (val) => {
      let filtered = new Fuse(items, {
        keys: limitToKeys,
        threshold: 0.3,
      }).search(val);
      // console.log(filtered);
      updateResults(filtered.map((f) => f.item));
      setSearchHits(filtered?.length);
    },
    [items, limitToKeys, updateResults]
  );

  const resetSearch = useCallback(() => {
    setSearchQuery(undefined);
    setSuggestionQuery(undefined);
    updateResults(items);
    setSearchHits(0);
  }, [updateResults, items]);

  const updateSearchMessage = useCallback(
    (val) => {
      returnSearchMessage(val);
    },
    [returnSearchMessage]
  );

  useEffect(() => {
    if (searchQuery) {
      updateSearchMessage(
        <div className="db__search-message">
          <span className="db__search-message__hits">
            {searchQuery?.length > 0 && searchHits > 0 ? searchHits : "ingen"}{" "}
            treff på søk:{" "}
          </span>
          <span className="db__search-message__query">
            <i>
              "<strong>{searchQuery}</strong>"
            </i>
          </span>
          <button
            className="ms-3 btn btn-outline-danger db__search-message__btn"
            onClick={() => resetSearch()}
          >
            <span>Fjern søk</span>
          </button>
        </div>
      );
    } else {
      updateSearchMessage("");
    }
  }, [searchQuery, searchHits, updateSearchMessage, resetSearch]);
  const [focused, setFocused] = useState(false);
  return (
    <div
      className="db__search-box"
      onFocus={() => setFocused(true)}
      onBlur={() => {
        setFocused(false);
      }}
    >
      <p className="mb-1">Søk etter</p>
      <label
        className="db__search"
        style={{
          width: focused ? "100%" : "90%",
        }}
      >
        <DebounceInput
          inputRef={searchBox}
          type="search"
          className="db__search__input"
          minLength={minLength}
          value={searchQuery || ""}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              resetSearch();
            }
            setSuggestionQuery(e.target.value.toLowerCase());
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setFocused(false);
              setSearchQuery(e.target.value.toLowerCase());
              doSearch(e.target.value.toLowerCase());
            }
          }}
          placeholder={placeholder}
          aria-label={ariaLabel}
        />
        <button
          className="db__search__btn"
          onClick={() => {
            setFocused(false);
            setSearchQuery(searchBox?.current.value?.toLowerCase());
            doSearch(searchBox?.current.value.toLowerCase());
          }}
        >
          <FadeIn transitionDuration="150">
            <FaSearch size="1.5rem" className="btn__icon" />
          </FadeIn>
        </button>
      </label>
      {focused && (
        <Suggestions
          limitToKeys={limitToKeys}
          q={suggestionQuery}
          items={items}
          returnSelected={(selected) => {
            doSearch(selected);
            setSearchQuery(selected);
            setSuggestionQuery(null);
          }}
        />
      )}
    </div>
  );
};

export default SearchBox;
