import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";

const LazyBlur = ({ src, placeholder, className, style = {} }) => {
  return (
    <ProgressiveImage src={src} placeholder={placeholder}>
      {(src, loading) => (
        <img
          loading="lazy"
          srl_gallery_image="true"
          alt={src}
          src={src}
          className={className}
          style={Object.assign(
            {
              opacity: loading ? 0.5 : 1,
              transition: "all 1s cubic-bezier(0.075, 0.82, 0.165, 1)",
            },
            style
          )}
        />
      )}
    </ProgressiveImage>
  );
};

export default LazyBlur;
