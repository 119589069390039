/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

// Contexts
import { DataContext } from "~/contexts/Data";

// Internal components
import Header from "~/components/Header";
import PageWrapper from "~/components/PageWrapper";

const Page = ({ bg }) => {
  const { umbData, setHeaderBgImg, setCurrentRoute } = useContext(DataContext);

  useEffect(() => {
    setCurrentRoute(umbData);
  }, [umbData]);
  useEffect(() => {
    if (umbData.bgImg?.umbracoFile) {
      setHeaderBgImg(umbData.bgImg?.umbracoFile);
    }
  }, [umbData]);
  return (
    <>
      <PageWrapper
        devData={umbData}
        page={umbData}
        seoTitle={umbData.seoTitle}
        seoDescription={umbData.seoDescription}
        seoImage={umbData.seoImage?.url}
        customHeader={
          <Header
            // title={umbData.title || ""}
            bgImg={umbData.bgImg?.umbracoFile}
            quote={umbData.quote}
            className="header--home"
          ></Header>
        }
      >
        {/* {data.indexPages.map((page) => (
            <>
              {Array.isArray(page.components) &&
                page.components.length > 0 &&
                page.components.map((c) => (
                  <>
                    <Components c={c} />
                  </>
                ))}
            </>
          ))} */}
      </PageWrapper>
    </>
  );
};

export default Page;
